/* @flow */

import React from 'react';
import { Col, Row } from 'react-grid-system';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  ActionButton,
  Divider,
  PageHeader,
  ListLargeText,
  LoadingIndicator,
  MenuOptionButton,
  OptionButtonGroup,
  PromptText,
} from 'listertech-commons-web';
import SideNavPage from 'components/SideNavPage';
import moment from 'moment';

class AllDocumentsPage extends React.Component {
  componentDidMount(): void {
    const {
      getAllDocumentsForContractor,
      match: {
        params: { contractorId },
      },
    } = this.props;
    getAllDocumentsForContractor(contractorId);
  }

  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  handleDownloadZip = () => {
    const {
      getDocumentsZipForContractor,
      match: {
        params: { contractorId },
      },
    } = this.props;
    getDocumentsZipForContractor(contractorId);
  };

  handleView(doctype, filename): void {
    const {
      viewDocument,
      match: {
        params: { contractorId },
      },
    } = this.props;
    viewDocument(doctype, filename, contractorId);
  }

  renderSignedAt(d): void {
    if (d.category !== 'signable') {
      return '';
    }
    if (d.signedAt) {
      return (
        <SmallTextGreen>
          Signed at {moment(d.signedAt).format('MM/DD/YYYY')}
        </SmallTextGreen>
      );
    }
    return <SmallTextRed>NOT SIGNED</SmallTextRed>;
  }

  renderCreatedAt(d): void {
    if (d.category !== 'scannable') {
      return '';
    }
    if (!d.files || !d.files.length) {
      return <SmallTextRed>NOT UPLOADED</SmallTextRed>;
    }
    if (d.createdAt) {
      return (
        <SmallTextGreen>
          Created at {moment(d.createdAt).format('MM/DD/YYYY')}
        </SmallTextGreen>
      );
    }
    return '';
  }

  renderFiles(d): void {
    if (d.files && d.files.length) {
      return (
        <ul>
          {d.files.map(f => (
            <li key={f.filename}>
              <FileContainer>
                <SmallTextLink
                  onClick={() => this.handleView(d.doctype, f.filename)}
                >
                  {f.filename}
                </SmallTextLink>
              </FileContainer>
            </li>
          ))}
        </ul>
      );
    }
    return '';
  }

  render() {
    const { loading, error, documents, loadingZip, errorZip } = this.props;
    return (
      <SideNavPage>
        <Row justify="between">
          <PageHeader>Documents</PageHeader>
          <OptionButtonGroup>
            <MenuOptionButton onClick={this.handleBack}>Back</MenuOptionButton>
            {loadingZip ? (
              <LoadingIndicator />
            ) : (
              <MenuOptionButton green onClick={this.handleDownloadZip}>
                Download all as ZIP
              </MenuOptionButton>
            )}
          </OptionButtonGroup>
        </Row>
        {errorZip ? (
          <Row justify="end">
            <Error>{errorZip}</Error>
          </Row>
        ) : null}
        {loading ? (
          <LoadingIndicator />
        ) : error ? (
          <PromptText>{error}</PromptText>
        ) : (
          documents.map(d => (
            <div key={d.doctype}>
              <ListLargeText>{d.title}</ListLargeText>
              {this.renderSignedAt(d)}
              {this.renderCreatedAt(d)}
              {this.renderFiles(d)}
              <Divider />
            </div>
          ))
        )}
      </SideNavPage>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: state.loading['GET_ALL_DOCUMENTS_FOR_USER'],
  loadingZip: state.loading['GET_DOCUMENTS_ZIP_FOR_USER'],
  error: state.error['GET_ALL_DOCUMENTS_FOR_USER'],
  errorZip: state.error['GET_DOCUMENTS_ZIP_FOR_USER'],
  documents: state.documents.all,
});

const mapDispatchToProps = dispatch => ({
  getAllDocumentsForContractor: (contractorId: string) =>
    dispatch({ type: 'GET_ALL_DOCUMENTS_FOR_USER', contractorId }),
  getDocumentsZipForContractor: (contractorId: string) =>
    dispatch({ type: 'GET_DOCUMENTS_ZIP_FOR_USER', contractorId }),
  viewDocument: (doctype: string, filename: string, contractorId: string) =>
    dispatch({ type: 'VIEW_DOCUMENT', doctype, filename, contractorId }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllDocumentsPage);

const SmallText = styled.p`
  font-size: 12px;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 15px;
`;

const SmallTextGreen = styled(SmallText)`
  color: #5fb894;
`;

const SmallTextRed = styled(SmallText)`
  color: #ef7171;
`;

const SmallTextLink = styled(SmallTextGreen)`
  text-decoration: underline;
  cursor: pointer;
  color: #0000ff;
`;

const FileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Error = styled(PromptText)`
  width: auto;
`;
