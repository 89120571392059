/* @flow */
import React, { Component, Fragment } from 'react';
import { Row, Col } from 'react-grid-system';
import { connect } from 'react-redux';
import querySearch from 'stringquery';
import type { RouterHistory } from 'react-router-dom';
import {
  Images,
  PageHeader,
  MenuOptionButton,
  PageSelector,
  OptionButtonGroup,
  LoadingIndicator,
  PromptText,
  MenuOptionsSearch,
  FiltersModal,
  PillsSelector,
} from 'listertech-commons-web';

import SideNavPage from 'components/SideNavPage';
import OpenTable from './OpenTable';
import AcceptedTable from './AcceptedTable';
import PendingTable from './PendingTable';
import CanceledTable from './CanceledTable';
import type { Visit } from 'data/visits';
import type { Paginator } from 'data/pagination';
import { PAGE_SIZE } from 'data/pagination/config';
import PaginationActions from 'data/pagination/actions';
import VisitsActions from 'data/visits/actions';
import { capitalize } from 'util/StringUtils';
import { confirmAlert } from 'react-confirm-alert';

import 'components/PaginationStyle/PaginationStyle.css';

import { VisitTypesList } from '../../data/visits';

type Props = {
  history: RouterHistory,
  getVisits: (
    status: string,
    page: number,
    sort: string,
    desc: boolean,
  ) => void,
  setTab: (tab: string) => void,
  setActivePage: (key: string, page: number) => void,
  setSearch: (search: string) => void,
  search: string,
  loading: boolean,
  error: string,
  all: Visit[],
  open: Paginator,
  accepted: Paginator,
  pending: Paginator,
  canceled: Paginator,
  paid: Paginator,
  tab: 'open' | 'accepted' | 'pending' | 'canceled',
  verifyVisit: (id: string) => void,
};

type State = {};

class VisitsPage extends Component<Props, State> {
  state = {
    filterModalVisible: false,
    sortOrder: {
      name: false,
    },
    tabButtons: [
      {
        Title: 'Open',
        Subtitle: 'Manage Patients',
        value: 'open',
      },
      {
        Title: 'Accepted',
        Subtitle: 'Manage Messages',
        value: 'accepted',
      },
      // {
      //   Title: 'Pending',
      //   Subtitle: 'Manage Prescriptions',
      //   value: 'pending',
      // },
      {
        Title: 'Canceled',
        Subtitle: 'Manage Prescriptions',
        value: 'canceled',
      },
    ],
  };

  componentDidMount() {
    this.fetchData(0, true);

    const query = querySearch(this.props.location.search);

    if (query.tab) {
      this.props.setTab(query.tab);
    }
  }

  componentDidUpdate(prevProps: Props): * {
    if (this.props.tab !== prevProps.tab) {
      this.fetchData(this.props[this.props.tab].currentPage || 0, true);
    }
  }

  openFiltersModal = () => {
    this.setState({
      filterModalVisible: true,
    });
  };

  closeFiltersModal = () => {
    this.setState({
      filterModalVisible: false,
    });
  };

  handleEditVisit = id => {
    this.props.history.push({
      pathname: '/visits/edit',
      state: {
        id,
      },
    });
  };

  handleRepostVisit = id => {
    this.props.history.push({
      pathname: '/visits/add',
      state: {
        id,
      },
    });
  };

  showConfirmAlert = (title, message, onConfirm) => {
    confirmAlert({
      title,
      message,
      buttons: [
        {
          label: 'Yes',
          onClick: onConfirm,
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  handleCancelVisit = id => {
    this.showConfirmAlert(
      'Cancel visit',
      'Are you sure you want to cancel this visit?',
      () => this.props.cancelVisit(id, 'open'),
    );
  };

  handlePendingVerify = id => {
    this.showConfirmAlert(
      'Verify visit',
      'Are you sure you want to verify this visit?',
      () => this.props.verifyVisit(id),
    );
  };

  handlePendingReject = id => {
    this.showConfirmAlert(
      'Reject visit',
      'Are you sure you want to reject this visit?',
      () => this.props.rejectVisit(id),
    );
  };

  handleCancelAcceptedVisit = id => {
    this.showConfirmAlert(
      'Cancel visit',
      'Are you sure you want to cancel this visit?',
      () => this.props.cancelVisit(id, 'accepted'),
    );
  };

  handlePageChange = (page, e) => {
    this.fetchData(page - 1);
  };

  applyFilters = () => {
    this.props.clearPages(this.props.tab);
    this.closeFiltersModal();

    setTimeout(() => {
      this.fetchData(0);
    });
  };

  fetchData = (page: number, forceFetch: boolean) => {
    if (!Boolean(this.props[this.props.tab].pages[page]) || forceFetch) {
      this.props.getVisits(
        capitalize(this.props.tab),
        page,
        'updated_at',
        true,
        this.props.search,
        this.props.filters,
      );
    } else {
      this.props.setActivePage(this.props.tab, page);
    }
  };

  handleTabChange = value => {
    this.props.setTab(value);
  };

  handleSort = key => {
    const { sortOrder } = this.state;
    this.setState({
      sortOrder: {
        [key]: sortOrder.hasOwnProperty(key) ? !sortOrder[key] : false,
      },
    });
  };

  handleCreateVisit = () => {
    this.props.history.push('/visits/add');
  };

  handleSearchChange = (search: string) => {
    this.props.setSearch(search);
    this.fetchData(0, true);
  };

  render() {
    const { tabButtons } = this.state;
    const { tab } = this.props;

    const paginator = this.props[tab];
    const ids = paginator.pages[paginator.currentPage || 0] || [];

    const visits = this.props.all
      .filter(v => ids.includes(v.id))
      .map(v => ({ ...v, link: `/visits/${v.id}` }));

    return (
      <SideNavPage>
        <PageHeader>Visits</PageHeader>
        <Row justify="between" style={{ marginBottom: '20px' }}>
          <PillsSelector
            isHorizontal
            buttons={tabButtons}
            value={tab}
            onChange={this.handleTabChange}
          />
          <OptionButtonGroup>
            <MenuOptionsSearch
              initialValue={this.props.search}
              onChange={this.handleSearchChange}
            />
            <MenuOptionButton round onClick={this.openFiltersModal}>
              <img
                style={{ cursor: 'pointer' }}
                src={Images.filter}
                alt="filter"
              ></img>
            </MenuOptionButton>
            <MenuOptionButton
              hidden={tab === 'accepted'}
              onClick={this.handleCreateVisit}
            >
              Create New
            </MenuOptionButton>
          </OptionButtonGroup>
        </Row>
        {this.props.loading ? (
          <LoadingIndicator />
        ) : this.props.error ? (
          <PromptText>{this.props.error}</PromptText>
        ) : (
          <>
            {tab === 'open' ? (
              <OpenTable
                data={visits}
                onSort={index => {}}
                onEdit={this.handleEditVisit}
                onCancel={this.handleCancelVisit}
              />
            ) : null}
            {tab === 'accepted' ? (
              <AcceptedTable
                data={visits}
                onCancel={this.handleCancelAcceptedVisit}
                onRepost={this.handleRepostVisit}
                onSort={index => {}}
              />
            ) : null}
            {/* {tab === 'pending' ? (
              <PendingTable
                data={visits}
                onSort={index => {}}
                onVerify={this.handlePendingVerify}
                onReject={this.handlePendingReject}
              />
            ) : null} */}
            {tab === 'canceled' ? (
              <CanceledTable
                data={visits}
                onSort={index => {}}
                onRepost={this.handleRepostVisit}
              />
            ) : null}
          </>
        )}
        {paginator.objectCount && paginator.pagesCount ? (
          <Row justify="end" style={{ marginRight: 20 }}>
            <PageSelector
              total={paginator.objectCount}
              limit={PAGE_SIZE}
              pageCount={paginator.pagesCount}
              currentPage={paginator.currentPage + 1 || 1}
              onPageChange={this.handlePageChange}
            />
          </Row>
        ) : null}
        <FiltersModal
          visible={this.state.filterModalVisible}
          title="Visit Types"
          onChangeFilters={this.props.setFilters}
          filters={this.props.filters}
          filtersOptions={VisitTypesList.map(type => ({
            value: type,
            label: type,
          }))}
          onClose={this.closeFiltersModal}
          onApply={this.applyFilters}
        />
      </SideNavPage>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.loading['GET_VISITS'],
    error: state.error['GET_VISITS'],
    all: state.visits.all,
    tab: state.visits.tab,
    search: state.visits.search,
    open: state.pagination.open,
    accepted: state.pagination.accepted,
    pending: state.pagination.pending,
    canceled: state.pagination.canceled,
    filters: state.visits.filters,
  };
};

const mapDispatchToProps = dispatch => ({
  getVisits: (status, page, sort, desc, search, filters) =>
    dispatch({ type: 'GET_VISITS', status, page, sort, desc, search, filters }),
  setTab: tab => dispatch({ type: 'SET_VISITS_TAB', tab }),
  setSearch: search => dispatch({ type: 'SET_VISITS_SEARCH', search }),
  setActivePage: (key, page) =>
    dispatch(PaginationActions.setActivePage(key, page)),
  clearPages: key => dispatch(PaginationActions.clearPages(key)),
  setFilters: filters => dispatch(VisitsActions.setVisitsFilters(filters)),
  verifyVisit: id => dispatch({ type: 'VERIFY_VISIT', id }),
  cancelVisit: (id, tab) => dispatch({ type: 'CANCEL_VISIT', id, tab }),
  rejectVisit: id => dispatch({ type: 'REJECT_VISIT', id }),
});

export default connect(mapStateToProps, mapDispatchToProps)(VisitsPage);
