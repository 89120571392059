/* @flow */
import { all } from 'redux-saga/effects';
import authenticationSaga from './AuthenticationSaga';
import agenciesSaga from './AgenciesSaga';
import contractorsSaga from './ContractorsSaga';
import visitsSaga from './VisitsSaga';
import documentsSaga from './DocumentsSaga';

export default function* root(): Generator<*, *, *> {
  yield all([
    ...authenticationSaga,
    ...agenciesSaga,
    ...contractorsSaga,
    ...visitsSaga,
    ...documentsSaga,
  ]);
}
