/* @flow */
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  callGetUserDocument,
  callGetUserScannables,
  callGetUserSignables,
  callGetUserArchive,
} from 'api/documents';
import DocumentsActions from '../data/documents/actions';

export function* viewDocument(action: {
  doctype: string,
  filename: string,
  contractorId: string,
}): Generator<*, *, *> {
  try {
    yield put({ type: 'VIEW_DOCUMENT_REQUEST' });
    const response: Object = yield call(
      callGetUserDocument,
      action.doctype,
      action.filename,
      action.contractorId,
    );
    const fileURL = URL.createObjectURL(response.data);
    window.open(fileURL);
    yield put({ type: 'VIEW_DOCUMENT_SUCCESS' });
  } catch (e) {
    yield put({ type: 'VIEW_DOCUMENT_FAILURE', error: e.message });
  }
}

export function* getAllDocumentsForUser(action: {
  contractorId: string,
}): Generator<*, *, *> {
  try {
    yield put({ type: 'GET_DOCUMENTS_ZIP_FOR_USER_CLEAR' });
    yield put({ type: 'GET_ALL_DOCUMENTS_FOR_USER_REQUEST' });
    yield put(DocumentsActions.setDocuments([]));
    const all = [];
    const scannablesResponse = yield call(
      callGetUserScannables,
      action.contractorId,
    );
    all.push(...scannablesResponse.data);
    const signablesResponse = yield call(
      callGetUserSignables,
      action.contractorId,
    );
    all.push(...signablesResponse.data);
    yield put(DocumentsActions.setDocuments(all));
    yield put({ type: 'GET_ALL_DOCUMENTS_FOR_USER_SUCCESS' });
  } catch (e) {
    yield put({ type: 'GET_ALL_DOCUMENTS_FOR_USER_FAILURE', error: e.message });
  }
}

export function* getDocumentsZipForUser(action: {
  contractorId: string,
}): Generator<*, *, *> {
  try {
    yield put({ type: 'GET_DOCUMENTS_ZIP_FOR_USER_REQUEST' });
    const response = yield call(callGetUserArchive, action.contractorId);
    const fileURL = URL.createObjectURL(response.data);
    window.open(fileURL);
    yield put({ type: 'GET_DOCUMENTS_ZIP_FOR_USER_SUCCESS' });
  } catch (e) {
    if (e.code === 404) {
      yield put({
        type: 'GET_DOCUMENTS_ZIP_FOR_USER_FAILURE',
        error: 'No files found',
      });
    } else {
      yield put({
        type: 'GET_DOCUMENTS_ZIP_FOR_USER_FAILURE',
        error: e.message,
      });
    }
  }
}

const agenciesSaga = [
  takeLatest('VIEW_DOCUMENT', viewDocument),
  takeLatest('GET_ALL_DOCUMENTS_FOR_USER', getAllDocumentsForUser),
  takeLatest('GET_DOCUMENTS_ZIP_FOR_USER', getDocumentsZipForUser),
];

export default agenciesSaga;
