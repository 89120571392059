/* @flow */

const initialState = {};

export default function loading(
  state: Object = initialState,
  action: { type: string },
) {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE|CLEAR)/.exec(type);
  if (!matches) {
    return state;
  }
  const [, requestName, requestState] = matches;
  return {
    ...state,
    [requestName]: requestState === 'REQUEST',
  };
}
