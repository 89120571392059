import * as ActionTypes from './actionTypes';

const setToken = (accessToken, expire) => ({
  type: ActionTypes.SET_TOKEN,
  accessToken,
  expire,
});

const clearToken = () => ({
  type: ActionTypes.CLEAR_TOKEN,
});

export default {
  setToken,
  clearToken,
};
