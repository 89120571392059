/* @flow */
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  callGetManagers,
  callGetPatients,
  callGetDetails,
  callUpdateDetails,
} from 'api/agencies';
import AgencyActions from 'data/agencies/actions';
import { ManagerConverter } from 'api/agencies/converters';
import { PatientConverter } from 'api/visits/converters';

export function* getManagers(action: {
  resolve: Function,
  reject: Function,
}): Generator<Object, void, void> {
  try {
    yield put({ type: 'GET_MANAGERS_REQUEST' });
    const response: Object = yield call(callGetManagers);
    yield put(
      AgencyActions.setManagers(new ManagerConverter().toEntityArray(response)),
    );
    yield put({ type: 'GET_MANAGERS_SUCCESS' });
  } catch (error) {
    yield put({
      type: 'GET_MANAGERS_FAILURE',
      error: error.message,
    });
  }
}

export function* getPatientSuggestions(action: {
  query: string,
  resolve: Function,
}): Generator<Object, void, void> {
  try {
    yield put({ type: 'GET_PATIENT_SUGGESTIONS_REQUEST' });
    const response: Object = yield call(callGetPatients, action.query);
    const entities = new PatientConverter().toEntityArray(response);
    yield put(AgencyActions.setPatientSuggestions(entities));
    yield put({ type: 'GET_PATIENT_SUGGESTIONS_SUCCESS' });
  } catch (error) {
    yield put({
      type: 'GET_PATIENT_SUGGESTIONS_FAILURE',
      error: error.message,
    });
  }
}

export function* clearPatientSuggestions(action) {
  yield put(AgencyActions.setPatientSuggestions([]));
}

export function* getDetails(action: {
  resolve: Function,
  reject: Function,
}): Generator<Object, void, void> {
  try {
    yield put({ type: 'GET_AGENCY_DETAILS_REQUEST' });
    const response: Object = yield call(callGetDetails);
    yield put(AgencyActions.setDetails(response));
    yield put({ type: 'GET_AGENCY_DETAILS_SUCCESS' });
  } catch (error) {
    yield put({
      type: 'GET_AGENCY_DETAILS_FAILURE',
      error: error.message,
    });
  }
}

export function* updateDetails(action): Generator<Object, void, void> {
  try {
    yield put({ type: 'UPDATE_AGENCY_DETAILS_REQUEST' });
    const response: Object = yield call(callUpdateDetails, action.data);
    // yield authenticateUser(response);
    yield put({ type: 'UPDATE_AGENCY_DETAILS_SUCCESS' });
    yield put(AgencyActions.setDetails(action.data));
    yield call(action.onSuccess);
  } catch (error) {
    yield call(action.onError, error.message);
    yield put({
      type: 'UPDATE_AGENCY_DETAILS_FAILURE',
      error: error.message,
    });
  }
}

const agenciesSaga = [
  takeLatest('GET_MANAGERS', getManagers),
  takeLatest('GET_PATIENT_SUGGESTIONS', getPatientSuggestions),
  takeLatest('CLEAR_PATIENT_SUGGESTIONS', clearPatientSuggestions),
  takeLatest('GET_AGENCY_DETAILS', getDetails),
  takeLatest('UPDATE_AGENCY_DETAILS', updateDetails),
];

export default agenciesSaga;
