import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import {
  DashboardOverviewElement,
  H5,
  DashboardTableHeader as TableHeader,
} from 'listertech-commons-web';
import SideNavPage from 'components/SideNavPage';
import AcceptedTable from './AcceptedVisitsTable';
import PendingTable from './PendingVisitsTable';
import ContractorsTable from './PendingEnrollmentsTable';
import { confirmAlert } from 'react-confirm-alert';

const COLORS = ['#B2DB88', '#5FB894', '#E4E986', '#86CB8E'];

class DashboardPage extends React.Component {
  componentDidMount(): void {
    this.props.getVisits();
    this.props.getContractors();
  }

  showConfirmAlert = (title, message, onConfirm) => {
    confirmAlert({
      title,
      message,
      buttons: [
        {
          label: 'Yes',
          onClick: onConfirm
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  onActiveDetails = id => {
    this.props.history.push(`/visits/${id}`);
  };

  viewAllVisits = (tab) => {
    this.props.history.push(`/visits?tab=${tab}`);
  };

  viewAllContractors = () => {
    this.props.history.push(`/clinicians?tab=pending`);
  };

  rejectVisit = (id: string) => {
    this.showConfirmAlert(
      'Reject visit',
      'Are you sure you want to reject this visit?',
      () =>  this.props.rejectVisit(id, this.props.getVisits),
    );
  };

  verifyVisit = (id: string) => {
    this.showConfirmAlert(
      'Verify visit',
      'Are you sure you want to verify this visit?',
      () => this.props.verifyVisit(id, this.props.getVisits),
    );
  };

  render() {
    const { enrollContractor, rejectContractor, verifyVisit, rejectVisit } = this.props;

    const count = this.props.visits.length;
    const visits = this.props.visits.map(v => ({ ...v, link: `/visits/${v.id}`}));
    const accepted = visits.filter(v => v.status === 'Accepted');
    const pending = visits.filter(v => v.status === 'Pending');

    const chartData = [
      { name: 'Available', value: Math.round(visits.filter(v => v.status === 'Open').length / count * 100 || 0)},
      { name: 'Accepted', value: Math.round(accepted.length / count * 100 || 0)},
      { name: 'Canceled', value: Math.round(visits.filter(v => v.status === 'Canceled').length / count * 100 || 0)},
      { name: 'Pending', value: Math.round(pending.length / count * 100 || 0)},
    ];

    const activeContractors = this.props.contractors.filter(c => c.enrollment === 'accepted');
    const pendingContractors = this.props.contractors
      .filter(c => c.enrollment === 'pending')
      .map(c => ({ ...c, link: `/clinicians/${c.id}`}));

    return (
      <SideNavPage>
        <Layout>
          <LayoutElement key="dash-overview" row="1" col="1">
            <H5>Overview</H5>
            <OverviewLayout>
              <DashboardOverviewElement title="Visits" subtitle="Total" value={count} background="#5FB89419"/>
              <DashboardOverviewElement title="Clinicians" subtitle="Active" value={activeContractors.length} background="#86CB8E19"/>
              {/*<DashboardOverviewElement title="Balance" subtitle="Current" value="$15.2k" />*/}
            </OverviewLayout>
          </LayoutElement>
          <LayoutElement key="dash-visits" row="1" col="2">
            <H5>Visits Breakdown</H5>
            <ChartWrapper>
              <ResponsiveContainer width={160} height={160}>
                <PieChart>
                  <Pie
                    data={chartData}
                    innerRadius={50}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {
                      chartData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                    }
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              <Legend>
                {chartData.map((entry, index) => <LegendElement key={`legend-${entry.name}`}><LegendCircle color={COLORS[index]}/>{entry.name}<span>({entry.value}%)</span></LegendElement>)}
              </Legend>
            </ChartWrapper>
          </LayoutElement>
          <LayoutElement  key="dash-pending-visits" wide row="2" col="1" wideRow="2" wideCol="1 / 3">
            <TableHeader title="Pending Visits" subtitle="Recent" onButtonClick={() => this.viewAllVisits('pending')}/>
            <PendingTable data={pending} onVerify={this.verifyVisit} onReject={this.rejectVisit} />
          </LayoutElement>
          <LayoutElement key="dash-active-visits" wide row="2" col="2" wideRow="3" wideCol="1 / 3">
            <TableHeader title="Active Visits" subtitle="Recent" onButtonClick={() => this.viewAllVisits('accepted')}/>
            <AcceptedTable data={accepted} onDetails={this.onActiveDetails} />
          </LayoutElement>
          <LayoutElement key="dash-pending-enrollments" wide row="3" col="1" wideRow="4" wideCol="1 / 3">
            <TableHeader title="Pending Enrollments" subtitle="Recent" onButtonClick={this.viewAllContractors}/>
            <ContractorsTable data={pendingContractors} onEnroll={enrollContractor} onReject={rejectContractor} />
          </LayoutElement>
        </Layout>
      </SideNavPage>
    );
  }
}

const OverviewLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 20px;
`;

const LayoutElement = styled.div`
  grid-column: ${props => props.col};
  grid-row: ${props => props.row};
  display: flex;
  flex-direction: column;

  ${props => props.wide && `
    @media (max-width: 1350px) {
      grid-column: ${props.wideCol};
      grid-row: ${props.wideRow};
    }
  `}

  h5 {
    width: 100%;
    margin: 0 0 15px;
    text-align: left;
  }
`;

const Layout = styled.div`
  padding-top: 20px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;

  @media (max-width: 993px) {
    grid-template-columns: auto;

    ${LayoutElement} {
      grid-column: auto;
      grid-row: auto;
    }
  }
`;

const ChartWrapper = styled.div`
  height: 100%;
  background-color: #298D7419;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
`;

const Legend = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 25px;
`;

const LegendElement = styled.div`
  display: flex;
  align-items: center;
  font-family: Montserrat;
  margin-bottom: 7px;
  font-size: 14px;

  span {
    font-weight: 200;
    margin-left: 5px;
  }
`;

const LegendCircle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: ${props => props.color};
  margin-right: 12px;
`;

const mapStateToProps = state => {
  return {
    loading: state.loading['GET_VISITS'],
    error: state.error['GET_VISITS'],
    visits: state.visits.dashboard,
    contractors: state.contractors.all,
    userAgencyId: state.user.agencyId,
  };
};

const mapDispatchToProps = dispatch => ({
  getVisits: () => dispatch({ type: 'GET_DASHBOARD_VISITS' }),
  getContractors: () => dispatch({ type: 'GET_CONTRACTORS' }),
  enrollContractor: id => dispatch({ type: 'ENROLL_CONTRACTOR', id }),
  rejectContractor: id => dispatch({ type: 'REJECT_CONTRACTOR', id }),
  verifyVisit: (id, onSuccess) => dispatch({ type: 'VERIFY_VISIT', id, onSuccess }),
  rejectVisit: (id, onSuccess) => dispatch({ type: 'REJECT_VISIT', id, onSuccess }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
