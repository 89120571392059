import { createGlobalStyle } from 'styled-components';
import { setConfiguration } from 'react-grid-system';

// Global grid style setting
setConfiguration({
  containerWidths: [540, 849, 849, 849],
  gutterWidth: 20,
});

const GlobalStyle = createGlobalStyle`
  /* Global styles */
  * {
    box-sizing: border-box;
  }
  #root {
    height: 100%;
  }
  body {
    margin: 0;
    padding: 0;
    height: 100vh;
    font-family: Lato;
  }

  .react-confirm-alert-body {
    box-shadow: none !important;
    font-family: Montserrat !important;
    color: #354148 !important;

    .react-confirm-alert-button-group button {
      &:nth-child(1) {
        background-color: #5FB894 !important;
        color: #FFFFFF !important;
      }

      &:nth-child(2) {
        background-color: #F7F8F8 !important;
        color: #B0BEC5 !important;
      }
    }
  }

  .react-confirm-alert-overlay {
    background-color: rgba(0, 0, 0, .1) !important;
  }
`;

export default GlobalStyle;
