/* @flow */
import { call } from '../core';
import type { RegisterAgencyDto } from './types';

export const callRegister = (dto: RegisterAgencyDto) =>
  call('agencies', 'POST', {
    body: dto,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const callGetDetails = () => call('agencies/details', 'GET', {});

export const callUpdateDetails = data =>
  call('agencies/update-details', 'POST', {
    body: data,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const callGetContractors = () => call('agencies/contractors', 'GET', {});

export const callGetContractor = (id: string) =>
  call(`agencies/contractors/${id}`, 'GET', {});

export const callGetManagers = () => call('agencies/managers', 'GET', {});

export const callGetPatients = searchTerm =>
  call('agencies/patients', 'GET', {
    params: { searchTerm, pageSize: 4 },
  });

export const callEnrollContractor = (id: string) =>
  call('agencies/contractors/accept', 'POST', {
    body: { contractor_id: id },
  });

export const callRejectContractor = (id: string) =>
  call('agencies/contractors/reject', 'POST', {
    body: { contractor_id: id },
  });

export const callUnenrollContractor = (id: string) =>
  call('agencies/contractors/unenroll', 'POST', {
    body: { contractor_id: id },
  });
