import * as ActionTypes from './actionTypes';

const setDocuments = documents => ({
  type: ActionTypes.SET_DOCUMENTS,
  documents,
});

export default {
  setDocuments,
};
