/* @flow */
import React from 'react';
import {DataTable, ListLargeText, ActionButton} from 'listertech-commons-web';
import type { Contractor } from 'data/contractors';

type Props = {
  data: Contractor[],
  onEnroll: (id: string) => void,
  onReject: (id: string) => void,
};

const PendingEnrollments = (props: Props) => {
  return (
    <DataTable
      name="pending-enrollments"
      headers={[
        { title: 'ID #', size: { sm: 5 }},
        { title: 'Name', size: { sm: 4 }},
        { title: 'Action', size: { sm: 3 }}
      ]}
      data={props.data.slice(0, 5)}
      renderers={[
        (c: Contractor) => <ListLargeText>{c.id}</ListLargeText>,
        (c: Contractor) => <ListLargeText>{c.name}</ListLargeText>,
        (c: Contractor) => (
          <>
            <ActionButton
              green
              onClick={(e) => {
                e.preventDefault();
                props.onEnroll(c.id)
              }}
              key={`pending-enrollments-enroll-${c.id}`}
            >
              Enroll
            </ActionButton>
            <ActionButton
              red
              onClick={(e) => {
                e.preventDefault();
                props.onReject(c.id)
              }}
              key={`pending-enrollments-reject-${c.id}`}
            >
              Reject
            </ActionButton>
          </>
        ),
      ]}
      onSort={() => {}}
    />
  );
};

export default PendingEnrollments;
