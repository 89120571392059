/* @flow */
import * as ActionTypes from './actionTypes';
import type { Manager } from './index';
import type { Patient } from '../visits';
import type {
  SetManagersAction,
  SetPatientSuggestionsAction,
} from './actionTypes';

const setManagers = (managers: Manager[]): SetManagersAction => ({
  type: ActionTypes.SET_MANAGERS,
  managers,
});

const setPatientSuggestions = (
  patients: Patient[],
): SetPatientSuggestionsAction => ({
  type: ActionTypes.SET_PATIENT_SUGGESTIONS,
  patients,
});

const setDetails = details => ({
  type: ActionTypes.SET_DETAILS,
  details,
});

const clear = () => ({
  type: ActionTypes.CLEAR,
});

export default {
  setManagers,
  setPatientSuggestions,
  setDetails,
  clear,
};
