/* @flow */
import type { VisitTypeDto, VisitStatusDto } from 'api/visits/types';

export type Patient = {
  name: string,
  dob: Date,
  age: number,
  gender: string,
  mr: string,
  diagnosis: string,
  visits: number,
  address: string,
  apartment: string,
  city: string,
  state: string,
  zip: string,
  phone: string,
  emergencyContact: string,
};

export type Doctor = {
  name: string,
  phone: string,
  ext: string,
};

export type CaseManager = {
  id: string,
  name: string,
  phone: string,
  ext: string,
};

export type VisitContractor = {
  id: string,
  name: string,
  phone: string,
};

export type SubmissionDetails = {
  acceptedDate: Date,
  submittedDate: Date,
  canceledDate: Date,
  paidDate: Date,
  reasonCanceled: string,
};

export type Visit = {
  id: string,
  price: number,
  type: VisitTypeDto,
  region: string,
  agencyName: string,
  agencyId: string,
  lat: number,
  lng: number,
  date: Date,
  status: VisitStatusDto,
  patient: Patient,
  doctor: Doctor,
  manager: CaseManager,
  contractor: VisitContractor | null,
  views: number,
  submission: SubmissionDetails | null,
};

export type VisitsState = {
  all: Visit[],
  dashboard: Visit[],
  tab: 'open' | 'accepted' | 'pending' | 'canceled',
};

export const VisitTypesList: string[] = [
  'PT OASIS SOC',
  'OT OASIS SOC',
  'PT Eval',
  'OT Eval',
  'PT Visit',
  'OT Visit',
  'PTA Visit',
  'COTA Visit',
  'PT Re-eval',
  'OT Re-eval',
  'PT OASIS RC',
  'OT OASIS RC',
  'PT Discharge',
  'OT Discharge',
  'PT OASIS ROC',
  'OT OASIS ROC',
];
