/* @flow */

const initialState = {};

export default function error(
  state: Object = initialState,
  action: { type: string, error: string },
) {
  const { type, error } = action;
  const matches = /(.*)_(CLEAR|REQUEST|FAILURE)/.exec(type);
  if (!matches) {
    return state;
  }
  const [, requestName, requestState] = matches;
  return {
    ...state,
    [requestName]: requestState === 'FAILURE' ? error : '',
  };
}
