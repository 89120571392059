/* @flow */
import type { Paginator } from './index';

export const SET_PAGE = 'pagination/SET_PAGE';
export const SET_ACTIVE_PAGE = 'pagination/SET_ACTIVE_PAGE';
export const CLEAR_PAGES = 'pagination/CLEAR_PAGES';
export const TAKE_FROM = 'pagination/TAKE_FROM';

export type SetPageAction = {
  type: typeof SET_PAGE,
  key: string,
  page: number,
  count: number,
  ids: string[],
};

export type SetActivePageAction = {
  type: typeof SET_ACTIVE_PAGE,
  key: string,
  page: number,
};

export type ClearPagesAction = {
  type: typeof CLEAR_PAGES,
  key: string,
};

export type TakeFromAction = {
  type: typeof TAKE_FROM,
  id: string,
  key: string,
};

export type PaginationActions =
  | SetPageAction
  | SetActivePageAction
  | ClearPagesAction
  | TakeFromAction;
