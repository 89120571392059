/* @flow */
import { call, put, takeLatest } from 'redux-saga/effects';
import SessionActions from 'data/session/actions';
import UserActions from 'data/user/actions';
import AgenciesActions from 'data/agencies/actions';
import {
  callLogin,
  callResetLink,
  callChangePassword,
  callDeleteAccount,
} from 'api/users';
import { callRegister } from 'api/agencies';
import type { RegisterAgencyDto } from 'api/agencies/types';
import decode from 'jwt-decode';

function* authenticateUser(user: Object): Generator<void, void, void> {
  const decoded = decode(user.token);
  const expire = decoded.exp * 1000;

  yield put(
    UserActions.setUser(
      user._id,
      user.email,
      user.agency_id ||
        (user.role === 'agency_user' ? '5db20fb73151a721df4fed50' : null), //FIXME
      user.name,
    ),
  );
  yield put(SessionActions.setToken(user.token, expire));
}

export function* login(action: {
  email: string,
  password: string,
}): Generator<Object, void, void> {
  try {
    yield put({ type: 'LOGIN_REQUEST' });
    const response: Object = yield call(
      callLogin,
      action.email,
      action.password,
    );
    yield authenticateUser(response.user);
    yield put({ type: 'LOGIN_SUCCESS' });
  } catch (error) {
    yield put({
      type: 'LOGIN_FAILURE',
      error: error.message || 'Invalid username or password',
    });
  }
}

export function* register(action: {
  data: RegisterAgencyDto,
  onSuccess: Function,
}): Generator<Object, void, void> {
  try {
    yield put({ type: 'REGISTER_REQUEST' });
    const response: Object = yield call(callRegister, action.data);
    // yield authenticateUser(response);
    yield put({ type: 'REGISTER_SUCCESS' });
    yield call(action.onSuccess);
  } catch (error) {
    yield put({
      type: 'REGISTER_FAILURE',
      error: error.message,
    });
  }
}

export function* logout(): Generator<void, void, void> {
  yield put(AgenciesActions.clear());
  yield put(UserActions.clearUser());
  yield put(SessionActions.clearToken());
  yield put({ type: 'RESET' });
}

export function* sendResetLink(action: {
  email: string,
  onSuccess: () => void,
}): Generator<Object, void, void> {
  try {
    yield put({ type: 'SEND_RESET_LINK_REQUEST' });
    const response: Object = yield call(callResetLink, action.email);
    yield put({ type: 'SEND_RESET_LINK_SUCCESS' });
    yield call(action.onSuccess);
  } catch (error) {
    yield put({
      type: 'SEND_RESET_LINK_FAILURE',
      error: error.message,
    });
  }
}

export function* savePasswords(action: {
  token: string,
  password: string,
}): Generator<Object, void, void> {
  try {
    yield put({ type: 'SAVE_PASSWORDS_REQUEST' });
    const response: Object = yield call(
      callChangePassword,
      action.token,
      action.password,
    );
    yield put({ type: 'SAVE_PASSWORDS_SUCCESS' });
    window.location.href = '/login';
  } catch (error) {
    yield put({
      type: 'SAVE_PASSWORDS_FAILURE',
      error: error.message,
    });
  }
}

export function* deleteAccount(action: {
  email: string,
  password: string,
  onSuccess: () => void,
}): Generator<Object, void, void> {
  try {
    yield put({ type: 'DELETE_ACCOUNT_REQUEST' });
    const response: Object = yield call(
      callDeleteAccount,
      action.email,
      action.password,
    );
    yield put({ type: 'DELETE_ACCOUNT_SUCCESS' });
    yield call(action.onSuccess);
  } catch (error) {
    yield put({
      type: 'DELETE_ACCOUNT_FAILURE',
      error: error.message,
    });
  }
}

const authSaga = [
  takeLatest('LOGIN', login),
  takeLatest('REGISTER', register),
  takeLatest('LOGOUT', logout),
  takeLatest('SEND_RESET_LINK', sendResetLink),
  takeLatest('SAVE_PASSWORDS', savePasswords),
  takeLatest('DELETE_ACCOUNT', deleteAccount),
];

export default authSaga;
