import React from 'react';
import {
  NavigationWrapper as Wrapper,
  NavigationMenuItem as MenuItem,
} from 'listertech-commons-web';

const navigation = ({ center = false, className, buttons, value, onChange, ishidden }) => {
    const handleChange = (e, value) => {
        e.preventDefault();
        onChange(value);
    };

    return (
        <Wrapper className={className}>
            {buttons.map(button => {
                let isActive = false;

                if (Array.isArray(value)) {
                    isActive = value.indexOf(button.value) > -1;
                } else {
                    isActive = value === button.value;
                }

                return (
                    <MenuItem
                        key={button.value}
                        center={center}
                        onClick={e => handleChange(e, button.value)}
                        active={isActive}>
                        <img src={button.Image} alt="nav"></img>
                        <label style={{ cursor: "pointer" }}>{button.Title}</label>
                    </MenuItem>
                );
            })}
        </Wrapper>
    );
};

export default navigation;
