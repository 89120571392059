import styled from 'styled-components';

const AccountForm = styled.form`
  width: 100%;
  position: relative;
  border-radius: 8px;
  background-color: white;
  padding: ${props => props.padding || '20px'};
  overflow: hidden;
  margin-top: 0px;
`;

export default AccountForm;
