/* @flow */
export function objectsDiff(original: Object, modified: Object) {
  var res = {};
  for (var key in modified) {
    if (modified[key] instanceof Date && original[key] instanceof Date) {
      if (modified[key].toISOString() !== original[key].toISOString()) {
        res[key] = modified[key];
      }
    } else if (
      Boolean(modified[key]) &&
      Boolean(original[key]) &&
      typeof modified[key] == 'object' &&
      typeof original[key] == 'object'
    ) {
      let d = objectsDiff(original[key], modified[key]);
      if (Object.entries(d).length > 0 && d.constructor === Object) {
        res[key] = d;
      }
    } else if (modified[key] != original[key]) {
      res[key] = modified[key];
    }
  }
  return res;
}
