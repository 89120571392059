import { call, callRaw } from '../core';

export const callGetUserDocument = (
  doctype: string,
  filename: string,
  contractorId: string,
) =>
  callRaw('documents/file/user', 'GET', {
    params: { doctype, filename, contractorId },
    responseType: 'blob',
  });

export const callGetUserScannables = (contractorId: string) =>
  callRaw('documents/scannables', 'GET', { params: { contractorId } });

export const callGetUserSignables = (contractorId: string) =>
  callRaw('documents/signables', 'GET', { params: { contractorId } });

export const callGetUserArchive = (contractorId: string) =>
  callRaw('documents/files/zip', 'GET', {
    params: { contractorId },
    responseType: 'blob',
  });
