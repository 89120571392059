/* @flow */
import * as ActionTypes from './actionTypes';
import type { AgencyActions } from './actionTypes';
import type { AgencyState } from './index';

const initialState: AgencyState = {
  managers: [],
  patientSuggestions: [],
  details: {},
};

export default function messages(
  state: AgencyState = initialState,
  action: AgencyActions,
) {
  switch (action.type) {
    case ActionTypes.SET_MANAGERS:
      return {
        ...state,
        managers: action.managers,
      };
    case ActionTypes.SET_PATIENT_SUGGESTIONS:
      return {
        ...state,
        patientSuggestions: [...action.patients],
      };
    case ActionTypes.SET_DETAILS:
      return {
        ...state,
        details: action.details,
      };
    case ActionTypes.CLEAR:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
