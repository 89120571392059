import { combineReducers, createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import LoadingReducer from './api/loadingReducer';
import ErrorReducer from './api/errorReducer';
import SessionReducer from './session/reducer';
import UserReducer from './user/reducer';
import AgencyReducer from './agencies/reducer';
import ContractorsReducer from './contractors/reducer';
import VisitsReducer from './visits/reducer';
import PaginationReducer from './pagination/reducer';
import DocumentsReducer from './documents/reducer';
import rootSaga from '../saga';
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';

const reducers = {
  loading: LoadingReducer,
  error: ErrorReducer,
  session: SessionReducer,
  user: UserReducer,
  agency: AgencyReducer,
  contractors: ContractorsReducer,
  visits: VisitsReducer,
  pagination: PaginationReducer,
  documents: DocumentsReducer,
};

const rootReducer = combineReducers(reducers);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['session', 'user'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  persistedReducer,
  applyMiddleware(sagaMiddleware, logger),
);

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
