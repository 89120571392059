import * as ActionTypes from './actionTypes';

const initialState = {
  accessToken: null,
  expire: null,
};

export default function messages(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_TOKEN:
      return {
        ...state,
        accessToken: action.accessToken,
        expire: action.expire,
      };
    case ActionTypes.CLEAR_TOKEN:
      return {
        ...state,
        accessToken: null,
        expire: null,
      };
    default:
      return state;
  }
}
