/* @flow */

import React from 'react';
import moment from 'moment';
import { Row, Col } from 'react-grid-system';
import type { RouterHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { confirmAlert } from 'react-confirm-alert';
import {
  PageHeader,
  OptionButtonGroup,
  MenuOptionButton,
  PageDetailHeader,
  Field,
  MainText,
  LoadingIndicator,
  Divider,
} from 'listertech-commons-web';

import SideNavPage from 'components/SideNavPage';
import type { Contractor } from 'data/contractors';

type Props = {
  history: RouterHistory,
  match: any,
  contractor: ?Contractor,
};

class ContractorDetailsPage extends React.Component<Props> {
  componentDidMount() {
    if (!this.props.contractor) {
      this.props.getContractor(this.props.match.params.contractorId);
    }
  }

  handleBack = () => {
    this.props.history.goBack();
  };

  showConfirmAlert = (title, message, onConfirm) => {
    confirmAlert({
      title,
      message,
      buttons: [
        {
          label: 'Yes',
          onClick: onConfirm,
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  handleRemoveContractor = () => {
    const { contractorId } = this.props.match.params;

    this.showConfirmAlert(
      'Remove clinician',
      'Are you sure you want to remove this clinician?',
      () => this.props.removeContractor(contractorId, this.handleBack),
    );
  };

  formatDate = (date, format = 'MM/DD') => {
    return date ? moment(date).format(format) : '';
  };

  formatAddress = ({ address, apartment, city, state, zip }) => {
    let result = '';
    if (apartment) {
      result += `Apartment ${apartment}, `;
    }
    if (address) {
      result += `${address}, `;
    }
    if (city) {
      result += `${city}, `;
    }
    if (state) {
      result += `${state} `;
    }
    if (zip) {
      result += `${zip}`;
    }
    return result;
  };

  handleView = (doctype, filename, contractorId) =>
    this.props.viewDocument(doctype, filename, contractorId);

  viewAllDocuments = () => {
    const { id } = this.props.contractor;
    this.props.history.push(`/documents/${id}`);
  };

  getFriendlyName = url => {
    const split = url.split('/');
    const filename = split[split.length - 1];

    return filename.replace('%20', ' ');
  };

  renderDoc = (doc, contractorId) =>
    doc.files.map(f => (
      <Col key={f.url} sm={12} md={6} lg={3} xl={3}>
        <Field
          label={
            doc.expiresAt
              ? `Expires ${moment(doc.expiresAt).format('MM/DD/YYYY')}`
              : 'No expiry date'
          }
          value={this.getFriendlyName(f.url)}
        />
        <MenuOptionButton
          onClick={() => this.handleView(doc.doctype, f.filename, contractorId)}
        >
          View
        </MenuOptionButton>
      </Col>
    ));

  renderNA = () => (
    <Col sm={12} md={6} lg={3} xl={3}>
      <MainText>N/A</MainText>
    </Col>
  );

  render() {
    const { contractor = {} } = this.props;

    const neededCertifications = [
      'PROFESSIONAL_INSURANCE',
      'AUTO_INSURANCE',
      'CPR_CARD',
    ];
    const neededLicences = ['PROFESSIONAL_LICENSE', 'DRIVER_LICENSE'];

    let certifications = [];
    let licenses = [];

    if (contractor.documents) {
      certifications = contractor.documents.filter(
        d => neededCertifications.indexOf(d.doctype) !== -1,
      );
      licenses = contractor.documents.filter(
        d => neededLicences.indexOf(d.doctype) !== -1,
      );
    }

    if (this.props.loading)
      return (
        <SideNavPage>
          <LoadingIndicator />
        </SideNavPage>
      );

    return (
      <SideNavPage>
        <Row justify="between">
          <PageHeader subtitle="Clinician">{contractor.name}</PageHeader>
          <OptionButtonGroup>
            <MenuOptionButton onClick={this.handleBack}>Back</MenuOptionButton>
            <MenuOptionButton red onClick={this.handleRemoveContractor}>
              Remove
            </MenuOptionButton>
          </OptionButtonGroup>
        </Row>
        <PageDetailHeader>Visits</PageDetailHeader>
        <Divider />
        <Row>
          <Col sm={12} md={6} lg={3} xl={3}>
            <Field label="Active" value={contractor.activeVisits} />
          </Col>
          <Col sm={12} md={6} lg={3} xl={3}>
            <Field label="Pending" value={contractor.pendingVisits} />
          </Col>
          <Col sm={12} md={6} lg={3} xl={3}>
            <Field label="Canceled" value={contractor.canceledVisits} />
          </Col>
        </Row>
        <PageDetailHeader>General Info</PageDetailHeader>
        <Divider />
        <Row>
          <Col sm={12} md={6} lg={3} xl={3}>
            <Field label="Name" value={contractor.name} />
          </Col>
          <Col sm={12} md={6} lg={3} xl={3}>
            <Field label="email" value={contractor.email} />
          </Col>
        </Row>
        <PageDetailHeader>Certifications</PageDetailHeader>
        <Divider />
        <Row>
          {certifications.length
            ? certifications.map(doc => this.renderDoc(doc, contractor.id))
            : this.renderNA()}
        </Row>
        <PageDetailHeader>Licences</PageDetailHeader>
        <Divider />
        <Row>
          {licenses.length
            ? licenses.map(doc => this.renderDoc(doc, contractor.id))
            : this.renderNA()}
        </Row>
        <ViewMore>
          <MenuOptionButton onClick={this.viewAllDocuments}>
            View All Documents
          </MenuOptionButton>
        </ViewMore>
        <PageDetailHeader>Transportation</PageDetailHeader>
        <Divider />
        <Row>
          <Col sm={12} md={6} lg={3} xl={3}>
            <Field label="Type" value="N/A" />
          </Col>
          <Col sm={12} md={6} lg={3} xl={3}>
            <Field label="Make" value="N/A" />
          </Col>
          <Col sm={12} md={6} lg={3} xl={3}>
            <Field label="Model" value="N/A" />
          </Col>
          <Col sm={12} md={6} lg={3} xl={3}>
            <Field label="Plate #" value="N/A" />
          </Col>
        </Row>
      </SideNavPage>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let contractor;
  contractor = state.contractors.all.find(
    v => v.id === ownProps.match.params.contractorId,
  );
  return {
    contractor,
    loading: state.loading['GET_CONTRACTOR'],
  };
};

const mapDispatchToProps = dispatch => ({
  viewDocument: (doctype: string, filename: string, contractorId: string) =>
    dispatch({ type: 'VIEW_DOCUMENT', doctype, filename, contractorId }),
  removeContractor: (id: string, onSuccess: () => void) =>
    dispatch({ type: 'REMOVE_CONTRACTOR', id, onSuccess }),
  getContractor: (id: string) => dispatch({ type: 'GET_CONTRACTOR', id }),
});

const ViewMore = styled.div`
  margin-top: 25px;
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContractorDetailsPage);
