import styled from 'styled-components';

const RegisterForm = styled.form`
  width: 100%;
  position: relative;
  border-radius: 8px;
  background-color: white;
  padding: ${props => props.padding || '20px'};
  overflow: hidden;
  margin-top: 0px;
  margin-right: 30px;
`;

export default RegisterForm;