/* @flow */
import React from 'react';
import moment from 'moment';
import { DataTable, ListLargeText, ActionButton } from 'listertech-commons-web';
import type { Visit } from 'data/visits';

type Props = {
  data: Visit[],
  onSort: (index: number) => void,
  onEdit: (id: string) => void,
  onCancel: (id: string) => void,
};

const OpenTable = (props: Props) => {
  return (
    <DataTable
      name="open-visits"
      headers={[
        { title: 'Patient Name', size: { xs: 3 } },
        { title: 'Date', size: { xs: 2 } },
        { title: 'Type', size: { xs: 3 } },
        { title: 'Views', size: { xs: 1 } },
        { title: 'Action', size: { xs: 3 } },
      ]}
      data={props.data}
      renderers={[
        (v: Visit) => <ListLargeText>{v.patient.name}</ListLargeText>,
        (v: Visit) => (
          <ListLargeText>{moment(v.date).format('MM/DD')}</ListLargeText>
        ),
        (v: Visit) => <ListLargeText>{v.type}</ListLargeText>,
        (v: Visit) => <ListLargeText>{v.views}</ListLargeText>,
        (v: Visit) => (
          <React.Fragment>
            <ActionButton
              onClick={e => {
                e.preventDefault();
                props.onEdit(v.id);
              }}
              key={`open-visits-edit-${v.id}`}
            >
              Edit
            </ActionButton>
            <ActionButton
              red
              onClick={e => {
                e.preventDefault();
                props.onCancel(v.id);
              }}
              key={`open-visits-cancel-${v.id}`}
            >
              Cancel
            </ActionButton>
          </React.Fragment>
        ),
      ]}
      onSort={props.onSort}
    />
  );
};

export default OpenTable;
