/* @flow */
import React from 'react';
import { Row } from 'react-grid-system';
import { connect } from 'react-redux';
import {
  PageHeader,
  LoadingIndicator,
  PromptText,
  DataTable,
  ListLargeText,
  RatingBar,
  ActionButton,
  PillsSelector,
} from 'listertech-commons-web';
import SideNavPage from 'components/SideNavPage';
import type { Contractor } from 'data/contractors';
import querySearch from 'stringquery';
import { confirmAlert } from 'react-confirm-alert';

type Props = {
  agencyId: string,
  loading: boolean,
  error: string,
  contractors: Contractor[],
  getContractors: () => void,
  enrollContractor: (id: string) => void,
  rejectContractor: (id: string) => void,
};

type State = {
  selectedPillValue: Object,
};

class ContractorsPage extends React.Component<Props, State> {
  ACTIVE_TABLE = {
    headers: ['Name', 'Active Visits'],
    renderers: [
      (c: Contractor) => <ListLargeText>{c.name}</ListLargeText>,
      (c: Contractor) => <ListLargeText>{c.activeVisits}</ListLargeText>,
    ],
  };

  PENDING_TABLE = {
    headers: ['Clinician', 'Action'],
    renderers: [
      (c: Contractor) => <ListLargeText>{c.name}</ListLargeText>,
      (c: Contractor) => (
        <React.Fragment>
          <ActionButton
            green
            onClick={() => this.handleEnroll(c.id)}
            key={`Enroll-${c.id}`}
          >
            Enroll
          </ActionButton>
          <ActionButton
            red
            onClick={() => this.handleReject(c.id)}
            key={`Reject-${c.id}`}
          >
            Reject
          </ActionButton>
        </React.Fragment>
      ),
    ],
  };

  PILLS = [
    {
      Title: 'Active',
      value: 'accepted',
    },
    {
      Title: 'Pending',
      value: 'pending',
    },
  ];

  state = {
    selectedPillValue: this.PILLS[0].value,
  };

  componentDidMount() {
    this.props.getContractors();

    const query = querySearch(this.props.location.search);

    if (query.tab) {
      this.setState({
        selectedPillValue: query.tab,
      })
    }
  }
  handleSort = field => {};
  handlePillChange = selectedPillValue => {
    this.setState({ selectedPillValue });
  };

  showConfirmAlert = (title, message, onConfirm) => {
    confirmAlert({
      title,
      message,
      buttons: [
        {
          label: 'Yes',
          onClick: onConfirm
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  handleEnroll = contractorId => {
    this.showConfirmAlert(
      'Enroll contractor',
      'Are you sure you want to enroll this contractor?',
      () => this.props.enrollContractor(contractorId),
    );
  };

  handleReject = contractorId => {
    this.showConfirmAlert(
      'Reject contractor',
      'Are you sure you want to reject this contractor?',
      () => this.props.rejectContractor(contractorId),
    );
  };

  render() {
    let isActivePill = this.state.selectedPillValue === this.PILLS[0].value;
    let tableDef = isActivePill ? this.ACTIVE_TABLE : this.PENDING_TABLE;
    let filteredContractors = this.props.contractors
      .filter(c => c.enrollment === this.state.selectedPillValue)
      .map(c => ({ ...c, link: `/clinicians/${c.id}`}));

    return (
      <SideNavPage>
        <PageHeader>Clinicians</PageHeader>
        <Row justify="between" style={{ marginBottom: '20px' }}>
          <PillsSelector
            isHorizontal
            buttons={this.PILLS}
            value={this.state.selectedPillValue}
            onChange={this.handlePillChange}
          />
        </Row>
        {this.props.loading ? (
          <LoadingIndicator />
        ) : this.props.error ? (
          <PromptText>{this.props.error}</PromptText>
        ) : (
          <DataTable
            headers={tableDef.headers}
            renderers={tableDef.renderers}
            data={filteredContractors}
            onSort={this.handleSort}
          />
        )}
      </SideNavPage>
    );
  }
}

const mapStateToProps = state => ({
  agencyId: state.user.agencyId,
  loading: state.loading['GET_CONTRACTORS'],
  error: state.error['GET_CONTRACTORS'],
  contractors: state.contractors.all,
});

const mapDispatchToProps = dispatch => ({
  getContractors: () => dispatch({ type: 'GET_CONTRACTORS' }),
  enrollContractor: id => dispatch({ type: 'ENROLL_CONTRACTOR', id }),
  rejectContractor: id => dispatch({ type: 'REJECT_CONTRACTOR', id }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractorsPage);
