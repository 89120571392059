import React from 'react';
import { connect } from 'react-redux';
import {
  Page,
  Card,
  LoginTitle,
  LoginSubtitle,
  Input,
  PromptText,
  Button,
} from 'listertech-commons-web';

class DeleteAccountPage extends React.Component {
  state = {
    email: '',
    password: '',
    error: '',
    deleted: false,
  };

  onSuccessfulDelete = () => {
    this.setState({ deleted: true });
  };

  handleValueChange = (field, e) => {
    this.setState({
      [field]: e.target.value,
    });
  };

  handleSubmit = () => {
    const { email, password } = this.state;

    if (!password || !email) {
      this.setState({
        error: 'All fields are required',
      });

      return;
    }

    // if (password !== passwordRepeat) {
    //   this.setState({
    //     error: 'Passwords do not match',
    //   });

    //   return;
    // }
    this.props.deleteAccount(email, password, this.onSuccessfulDelete);
  };

  render() {
    const { email, password, error, deleted } = this.state;

    return (
      <Page>
        <Card>
          <div style={{ width: '30%' }}>
            <form>
              <LoginTitle>Visitry</LoginTitle>
              <LoginSubtitle>Delete Account</LoginSubtitle>
              <Input
                type="email"
                name="Email"
                placeholder="Email"
                value={email}
                onChange={e => this.handleValueChange('email', e)}
              />
              <PromptText />
              <Input
                type="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={e => this.handleValueChange('password', e)}
              />
              <PromptText>{error}</PromptText>
              <PromptText>{this.props.error}</PromptText>

              <PromptText success={true}>
                {deleted && this.props.error.length == 0
                  ? 'Account deleted successfully'
                  : ''}
              </PromptText>

              <Button
                onClick={this.handleSubmit}
                theme="dark"
                loading={this.props.loading}
              >
                Delete Account
              </Button>
            </form>
          </div>
        </Card>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.loading['DELETE_ACCOUNT'],
  error: state.error['DELETE_ACCOUNT'],
});

const mapDispatchToProps = dispatch => ({
  deleteAccount: (email: string, password: string, onSuccess: () => void) =>
    dispatch({ type: 'DELETE_ACCOUNT', email, password, onSuccess }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccountPage);
