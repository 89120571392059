/* @flow */
import { call, callRaw } from '../core';
import type { VisitDto, CreateVisitDto, UpdateVisitDto } from './types';

export const callGetVisits = (
  status: string,
  pageSize: number,
  page: number,
  sort: string,
  desc: boolean,
  search?: string,
  filters: string[],
) =>
  callRaw('visits', 'GET', {
    params: {
      statuses: status,
      page_size: pageSize,
      page: page,
      sort_by: sort,
      desc: desc,
      search,
      types: filters,
    },
  });

export const callGetVisit = (id: string) => call(`visits/${id}`, 'GET', {});

export const callAddVisit = (visit: CreateVisitDto) =>
  call('visits', 'POST', { body: { ...visit } });

export const callEditVisit = (visit: UpdateVisitDto) =>
  call('visits', 'PATCH', { body: { ...visit } });

export const callVerifyVisit = (id: string) =>
  call('visits/verify', 'POST', { body: { visit_id: id } });

export const callRejectVisit = (id: string) =>
  call('visits/reject', 'POST', { body: { visit_id: id } });

export const callCancelVisit = (id: string) =>
  call('visits/cancel', 'POST', { body: { visit_id: id, reason: 'N/A' } });

export const callRemoveVisit = (id: string) =>
  call('visits/remove', 'POST', { body: { visit_id: id } });
