/* @flow */

import React from 'react';
import { Row, Col } from 'react-grid-system';
import type { RouterHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  PageHeader,
  OptionButtonGroup,
  MenuOptionButton,
  PageDetailHeader,
  Input,
  Dropdown,
  Autocomplete,
  InputCurrency,
  InputDate,
  Button,
  PromptText,
  Images,
} from 'listertech-commons-web';
import SideNavPage from 'components/SideNavPage';
import validate from 'util/ValidationWrapper';
import type { Patient, Doctor, Visit, VisitContractor } from 'data/visits';
import type {
  VisitTypeDto,
  CreateVisitDto,
  UpdateVisitDto,
} from 'api/visits/types';
import type { Manager } from 'data/agencies';
import type { Contractor } from 'data/contractors';
import GenderList from 'data/const/gender';
import UsaStatesList from 'data/const/states';
import {
  patientValidationRules,
  managerValidationRules,
  doctorValidationRules,
  contractorValidationRules,
  formValidationRules,
} from './validation';
import { objectsDiff } from 'util/FormUtils';

type Props = {
  history: RouterHistory,
  managers: Manager[],
  contractors: Contractor[],
  loading: boolean,
  managersError: string,
  contractorsError: string,
  getManagers: () => void,
  getContractors: () => void,
  addVisit: (
    visit: CreateVisitDto,
    resolve: Function,
    reject: Function,
  ) => void,
  editVisit: (
    visit: UpdateVisitDto,
    resolve: Function,
    reject: Function,
  ) => void,
  visitToChange: ?Visit,
};

type PatientErrors = {
  name: string,
  dob: string,
  gender: string,
  mr: string,
  diagnosis: string,
  address: string,
  apartment: string,
  city: string,
  state: string,
  zip: string,
  phone: string,
  emergencyContact: string,
};

type ManagerErrors = {
  name: string,
  phone: string,
  ext: string,
};

type DoctorErrors = {
  name: string,
  phone: string,
  ext: string,
};

type ContractorErrors = {
  name: string,
};

type FormErrors = {
  typeDiscepline: string,
  typeValue: string,
  date: string,
  price: string,
  manager: ManagerErrors,
  patient: PatientErrors,
  doctor: DoctorErrors,
  contractor: ContractorErrors,
};

type State = {
  typeDiscepline: ?string,
  typeValue: ?VisitTypeDto,
  typesList: string[],
  date: ?Date,
  price: number,
  patient: {
    ...$Exact<Patient>,
    dob: ?Date,
    age: ?number,
    visits?: number,
  },
  manager: {
    id: string,
    name: string,
    phone: string,
    ext: string,
  },
  doctor: Doctor,
  contractor: {
    id: string,
    name: string,
  },
  formErrors: FormErrors,
};

const typeDisceplineOptions: string[] = ['PT', 'OT', 'PTA', 'COTA'];
const disciplineTypes = {
  TypesList_PT: [
    'PT OASIS SOC',
    'PT Eval',
    'PT Visit',
    'PT Re-eval',
    'PT OASIS RC',
    'PT Discharge',
    'PT OASIS ROC',
  ],
  TypesList_OT: [
    'OT OASIS SOC',
    'OT Eval',
    'OT Visit',
    'OT Re-eval',
    'OT OASIS RC',
    'OT Discharge',
    'OT OASIS ROC',
  ],
  TypesList_PTA: ['PTA Visit'],
  TypesList_COTA: ['COTA Visit'],
};

class AddVisitPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      typeDiscepline: null,
      typeValue: null,
      typesList: [],
      date: null,
      price: 0,
      patient: {
        name: '',
        dob: null,
        age: null,
        gender: '',
        mr: '',
        diagnosis: '',
        address: '',
        apartment: '',
        city: '',
        state: 'FL',
        zip: '',
        phone: '',
        emergencyContact: '',
      },
      manager: {
        id: '',
        name: '',
        phone: '',
        ext: '',
      },
      doctor: {
        name: '',
        phone: '',
        ext: '',
      },
      contractor: {
        id: '',
        name: '',
      },
      formErrors: {
        typeDiscepline: '',
        typeValue: '',
        date: '',
        price: '',
        patient: {
          name: '',
          dob: '',
          gender: '',
          mr: '',
          diagnosis: '',
          address: '',
          apartment: '',
          city: '',
          state: '',
          zip: '',
          phone: '',
          emergencyContact: '',
        },
        manager: {
          name: '',
          phone: '',
          ext: '',
        },
        doctor: {
          name: '',
          phone: '',
          ext: '',
        },
        contractor: {
          name: '',
        },
      },
    };
  }
  componentDidMount() {
    if (!this.props.managers || this.props.managers.length === 0) {
      this.props.getManagers();
    }
    if (!this.props.contractors || this.props.contractors.length === 0) {
      this.props.getContractors();
    }
    if (this.props.visitToChange) {
      let v = this.props.visitToChange;
      const typeDiscepline = v.type.split(' ')[0];
      let listName = 'TypesList_';
      const typesList = disciplineTypes[listName + typeDiscepline];

      this.setState({
        typeDiscepline,
        // $$FlowFixMe
        typeValue: v.type,
        typesList,
        date: v.date,
        price: v.price,
        patient: {
          name: v.patient.name,
          dob: v.patient.dob,
          age: v.patient.age,
          gender: v.patient.gender,
          mr: v.patient.mr,
          diagnosis: v.patient.diagnosis,
          address: v.patient.address,
          apartment: v.patient.apartment,
          city: v.patient.city,
          state: v.patient.state,
          zip: v.patient.zip,
          phone: v.patient.phone,
          emergencyContact: v.patient.emergencyContact,
        },
        manager: {
          id: v.manager.id,
          name: v.manager.name,
          phone: v.manager.phone,
          ext: v.manager.ext,
        },
        doctor: {
          name: v.doctor.name,
          phone: v.doctor.phone,
          ext: v.doctor.ext,
        },
        contractor: {
          id: v.contractor ? v.contractor.id : '',
          name: v.contractor ? v.contractor.name : '',
        },
      });
    }
  }
  handleBack = () => {
    this.props.history.goBack();
  };
  handleChange = (name: string, value: string, section?: string) => {
    if (!section) {
      this.setState({
        [name]: value,
        formErrors: {
          ...this.state.formErrors,
          [name]: '',
        },
      });
    } else {
      this.setState({
        [section]: {
          ...this.state[section],
          [name]: value,
        },
        formErrors: {
          ...this.state.formErrors,
          [section]: {
            ...this.state.formErrors[section],
            [name]: '',
          },
        },
      });
    }
  };
  handleInputChange = (ev: Object, section?: string) => {
    const name = ev.target.name;
    const value = ev.target.value;
    return this.handleChange(name, value, section);
  };
  handleDisciplineChange = (ev: Object) => {
    const discipline = ev.target.value;
    let listName = 'TypesList_';

    switch (discipline) {
      case 'PT':
        listName += 'PT';
        break;
      case 'OT':
        listName += 'OT';
        break;
      case 'PTA':
        listName += 'PTA';
        break;
      case 'COTA':
        listName += 'COTA';
        break;
    }

    this.setState((prevState, props) => ({
      typeDiscepline: discipline,
      typesList: disciplineTypes[listName],
      typeValue: '',
      formErrors: {
        ...prevState.formErrors,
        typeDiscepline: '',
      },
    }));
  };
  handleDateChange = (date: Date) => {
    this.setState({
      date,
      formErrors: {
        ...this.state.formErrors,
        date: '',
      },
    });
  };
  handlePatientSelected = (name: string) => {
    const { patientSuggestions } = this.props;
    const patient = patientSuggestions.find(
      p => p.name && name && p.name.toLowerCase() === name.toLowerCase(),
    );
    if (!patient) {
      return;
    }

    this.setState({
      patient: {
        name: patient.name,
        dob: patient.dob,
        age: patient.age,
        gender: patient.gender,
        mr: patient.mr,
        diagnosis: patient.diagnosis,
        address: patient.address,
        apartment: patient.apartment,
        city: patient.city,
        state: patient.state,
        zip: patient.zip,
        phone: patient.phone,
        emergencyContact: patient.emergencyContact,
      },
      formErrors: {
        ...this.state.formErrors,
        patient: {
          name: '',
          dob: '',
          gender: '',
          mr: '',
          diagnosis: '',
          address: '',
          apartment: '',
          city: '',
          state: '',
          zip: '',
          phone: '',
          emergencyContact: '',
        },
      },
    });
  };

  handleContractorChange = (name: string) => {
    const contractor = this.props.contractors.find(c => c.name === name);
    this.setState({
      contractor: {
        id: contractor ? contractor.id : '',
        name: name,
      },
      formErrors: {
        ...this.state.formErrors,
        contractor: {
          name: '',
        },
      },
    });
  };

  fetchPatientSuggestions = (query: string) => {
    const { getPatientSuggestions } = this.props;
    getPatientSuggestions(query);
  };

  handleCreateVisit = () => {
    let patientErrors: PatientErrors = this.validatePatient();
    let managerErrors: ManagerErrors = this.validateManager();
    let doctorErrors: DoctorErrors = this.validateDoctor();
    let contractorErrors: ContractorErrors = this.validateContractor();
    let formErrors: FormErrors = this.validateForm();
    if (
      patientErrors ||
      managerErrors ||
      doctorErrors ||
      contractorErrors ||
      formErrors
    ) {
      this.setState({
        formErrors: {
          ...this.state.formErrors,
          ...formErrors,
          patient: {
            ...this.state.formErrors.patient,
            ...patientErrors,
          },
          manager: {
            ...this.state.formErrors.manager,
            ...managerErrors,
          },
          doctor: {
            ...this.state.formErrors.doctor,
            ...doctorErrors,
          },
          contractor: {
            ...this.state.formErrors.contractor,
            ...contractorErrors,
          },
        },
      });
    } else {
      if (this.props.visitToChange) {
        if (this.props.visitToChange.status==='Open'){
          this.callEditVisit(this.props.visitToChange);
        }
        else {
          this.callAddVisit();
        }
        
      } else {
        this.callAddVisit();
      }
    }
  };
  validatePatient = (): Object => {
    let {
      name,
      dob,
      gender,
      mr,
      diagnosis,
      address,
      apartment,
      city,
      state,
      zip,
      phone,
      emergencyContact,
    } = this.state.patient;
    return validate(
      {
        name,
        dob,
        gender,
        mr,
        diagnosis,
        address,
        apartment,
        city,
        state,
        zip,
        phone,
        emergencyContact,
      },
      patientValidationRules,
    );
  };
  validateManager = (): Object => {
    let { name, phone, ext } = this.state.manager;
    return validate({ name, phone, ext }, managerValidationRules);
  };
  validateDoctor = (): Object => {
    let { name, phone, ext } = this.state.doctor;
    return validate({ name, phone, ext }, doctorValidationRules);
  };
  validateContractor = (): Object => {
    let { id, name } = this.state.contractor;
    return validate({ id, name }, contractorValidationRules);
  };
  validateForm = (): Object => {
    let { typeDiscepline, typeValue, date, price } = this.state;
    return validate(
      { typeDiscepline, typeValue, date, price },
      formValidationRules,
    );
  };
  callAddVisit = () => {
    this.props.addVisit(
      {
        price: this.state.price,
        // $$FlowFixMe
        type: `${this.state.typeValue}` || 'PT Visit',
        date: this.state.date ? this.state.date.toISOString() : '',
        patient: {
          name: this.state.patient.name,
          mr_number: this.state.patient.mr,
          gender: this.state.patient.gender,
          dob: this.state.patient.dob
            ? this.state.patient.dob.toISOString()
            : '',
          diagnosis: this.state.patient.diagnosis,
          phone: this.state.patient.phone,
          emergency_contact: this.state.patient.emergencyContact,
          address: this.state.patient.address,
          apartment: this.state.patient.apartment,
          city: this.state.patient.city,
          state: this.state.patient.state,
          zip: this.state.patient.zip,
        },
        doctor: this.state.doctor,
        case_manager_id: this.state.manager.id,
        case_manager_phone: this.state.manager.phone,
        case_manager_ext: this.state.manager.ext,
        contractor_id: Boolean(this.state.contractor.id)
          ? this.state.contractor.id
          : null,
      },
      this.onApiSuccess,
      this.onApiFail,
    );
  };
  callEditVisit = (v: Visit) => {
    let state: Object = this.state;
    let req: UpdateVisitDto = {
      _id: v.id,
    };

    let diff: Object = objectsDiff(v, state);
    delete diff.formErrors;

    for (var key in diff) {
      if (key === 'date') {
        req[key] = diff[key].toISOString();
      } else if (key === 'manager') {
        if (diff.manager.id) {
          req.case_manager_id = diff.manager.id;
        }
        if (diff.manager.phone) {
          req.case_manager_phone = diff.manager.phone;
        }
        if (diff.manager.ext) {
          req.case_manager_ext = diff.manager.ext;
        }
      } else if (key === 'patient') {
        req.patient = diff.patient;
        if (req.patient.mr) {
          req.patient.mr_number = req.patient.mr;
          delete req.patient.mr;
        }
        if (req.patient.dob) {
          req.patient.dob = req.patient.dob
            ? req.patient.dob.toISOString()
            : null;
        }
        if (req.patient.emergencyContact) {
          req.patient.emergency_contact = req.patient.emergencyContact;
          delete req.patient.emergencyContact;
        }
      } else if (key === 'contractor') {
        if (diff.contractor.id) {
          req.contractor_id = diff.contractor.id;
        }
      } else if (key === 'typesList') {
        delete req.typesList;
      } else if (key === 'typeValue') {
        if (diff.typeValue !== '') {
          req.type = diff.typeValue;
          delete req.typeValue;
        }
      } else if (key === 'typeDiscepline') {
        req.type = diff.typeDiscepline;
        delete req.typeDiscepline;
      } else {
        req[key] = diff[key];
      }
    }

    this.props.editVisit(req, this.onApiSuccess, this.onApiFail);
  };
  onApiSuccess = () => {
    this.props.history.goBack();
  };
  onApiFail = err => {
    alert(err);
  };
  render() {
    const {
      typeDiscepline,
      typeValue,
      date,
      price,
      patient,
      manager,
      doctor,
      contractor,
      formErrors,
    } = this.state;

    const patientErrors = formErrors.patient;
    const managerErrors = formErrors.manager;
    const doctorErrors = formErrors.doctor;
    const contractorErrors = formErrors.contractor;

    const { managers, contractors, patientSuggestions } = this.props;
    const managersDropdownList = managers.map(m => m.name);
    const contractorsDropdownList = contractors.map(c => c.name);
    const patientsDropdownList = patientSuggestions.map(p => p.name);

    return (
      <SideNavPage>
        <Row justify="between">
          <PageHeader>
            {this.props.visitToChange ? this.props.visitToChange.status==='Open'? 'Edit Visit':'Create New Visit' : 'Create New Visit'}
          </PageHeader>
          <OptionButtonGroup>
            <MenuOptionButton onClick={this.handleBack}>Back</MenuOptionButton>
            <MenuOptionButton
              green
              icon={this.props.visitToChange ? Images.saveGreen : undefined}
              onClick={this.handleCreateVisit}
            >
              {this.props.visitToChange ? this.props.visitToChange.status==='Open'? 'Save':'Create Visit' : 'Create Visit'}
            </MenuOptionButton>
          </OptionButtonGroup>
        </Row>
        <PageDetailHeader>Visit Info</PageDetailHeader>
        <Row>
          <Col sm={6} md={3} lg={2} xl={2}>
            <Dropdown
              label="Discipline"
              name="typeDiscepline"
              value={typeDiscepline}
              options={typeDisceplineOptions}
              onChange={ev => this.handleDisciplineChange(ev)}
              isError={Boolean(formErrors.typeDiscepline)}
              error={formErrors.typeDiscepline}
            />
          </Col>
          <Col sm={6} md={4} lg={3} xl={2}>
            <Dropdown
              label="Type"
              name="typeValue"
              value={typeValue}
              options={this.state.typesList}
              onChange={ev => this.handleInputChange(ev)}
              isError={Boolean(formErrors.typeValue)}
              error={formErrors.typeValue}
            />
          </Col>
          <Col sm={12} md={4} lg={3} xl={2}>
            <InputDate
              label="Date"
              value={date}
              onDateChange={d => this.handleDateChange(d)}
              isError={Boolean(formErrors.date)}
              error={formErrors.date}
              format="MM/dd"
              minDate={new Date()}
            />
          </Col>
        </Row>
        <PageDetailHeader>Patient Info</PageDetailHeader>
        <Row>
          <Col sm={12} lg={6} xl={4}>
            <Autocomplete
              label="Patient Name"
              placeholder="First and Last Name"
              items={patientsDropdownList}
              value={patient.name}
              isError={Boolean(patientErrors.name)}
              error={patientErrors.name}
              onChange={name => this.handleChange('name', name, 'patient')}
              onFetchData={query => this.fetchPatientSuggestions(query)}
              onSelected={name => this.handlePatientSelected(name)}
            />
          </Col>
          <Col sm={12} lg={6} xl={4}>
            <Input
              label="Details"
              name="diagnosis"
              value={patient.diagnosis}
              onChange={ev => this.handleInputChange(ev, 'patient')}
              isError={Boolean(patientErrors.diagnosis)}
              error={patientErrors.diagnosis}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={8} md={7} lg={3} xl={3}>
            <Input
              label="Street Address"
              name="address"
              value={patient.address}
              onChange={ev => this.handleInputChange(ev, 'patient')}
              isError={Boolean(patientErrors.address)}
              error={patientErrors.address}
            />
          </Col>
          <Col sm={4} md={4} lg={2} xl={2}>
            <Input
              label="Apt #"
              name="apartment"
              value={patient.apartment}
              onChange={ev => this.handleInputChange(ev, 'patient')}
              isError={Boolean(patientErrors.apartment)}
              error={patientErrors.apartment}
            />
          </Col>
          <Col sm={12} md={5} lg={3} xl={3}>
            <Input
              label="City"
              name="city"
              value={patient.city}
              onChange={ev => this.handleInputChange(ev, 'patient')}
              isError={Boolean(patientErrors.city)}
              error={patientErrors.city}
            />
          </Col>
          <Col sm={4} md={3} lg={2} xl={2}>
            <Dropdown
              label="State"
              name="state"
              value={patient.state}
              options={UsaStatesList}
              onChange={ev => this.handleInputChange(ev, 'patient')}
              isError={Boolean(patientErrors.state)}
              error={patientErrors.state}
            />
          </Col>
          <Col sm={8} md={4} lg={2} xl={2}>
            <Input
              label="Zip"
              name="zip"
              value={patient.zip}
              onChange={ev => this.handleInputChange(ev, 'patient')}
              isError={Boolean(patientErrors.zip)}
              error={patientErrors.zip}
            />
          </Col>
          <Col sm={8} md={6} lg={4} xl={3}>
            <Input
              label="Patient Phone"
              name="phone"
              value={patient.phone}
              onChange={ev => this.handleInputChange(ev, 'patient')}
              isError={Boolean(patientErrors.phone)}
              error={patientErrors.phone}
            />
          </Col>
        </Row>
        <PageDetailHeader>Clinician</PageDetailHeader>
        <Row>
          <Col sm={12} md={6} lg={4} xl={3}>
            <Autocomplete
              label="Name"
              items={contractorsDropdownList}
              value={contractor.name}
              isError={Boolean(contractorErrors.name)}
              error={contractorErrors.name}
              onChange={name => this.handleContractorChange(name)}
            />
          </Col>
          <Col sm={12}>
            {this.props.contractorsError ? (
              <PromptText>{this.props.contractorsError}</PromptText>
            ) : null}
          </Col>
        </Row>
        <Button
          onClick={this.handleCreateVisit}
          theme="dark"
          loading={this.props.loading}
        >
          {this.props.visitToChange ? this.props.visitToChange.status==='Open'? 'Save':'Create Visit' : 'Create Visit'}
        </Button>
      </SideNavPage>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let visitToChange;
  if (ownProps.location.state) {
    visitToChange = state.visits.all.find(
      v => v.id === ownProps.location.state.id,
    );

    if (!visitToChange) {
      visitToChange = state.visits.dashboard.find(
        v => v.id === ownProps.location.state.id,
      );
    }
  }
  return {
    managers: state.agency.managers,
    patientSuggestions: state.agency.patientSuggestions,
    contractors: state.contractors.all,
    loading: state.loading['ADD_VISIT'] || state.loading['EDIT_VISIT'],
    managersError: state.error['GET_MANAGERS'],
    contractorsError: state.error['GET_CONTRACTORS'],
    visitToChange,
  };
};

const mapDispatchToProps = dispatch => ({
  getManagers: (resolve, reject) =>
    dispatch({ type: 'GET_MANAGERS', resolve, reject }),
  getContractors: () => dispatch({ type: 'GET_CONTRACTORS' }),
  getPatientSuggestions: (query: string, resolve) =>
    dispatch({ type: 'GET_PATIENT_SUGGESTIONS', query, resolve }),
  clearPatientSuggestions: () =>
    dispatch({ type: 'CLEAR_PATIENT_SUGGESTIONS' }),
  addVisit: (data: CreateVisitDto, resolve, reject) =>
    dispatch({ type: 'ADD_VISIT', data, resolve, reject }),
  editVisit: (data: UpdateVisitDto, resolve, reject) =>
    dispatch({ type: 'EDIT_VISIT', data, resolve, reject }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddVisitPage);
