import React, { Component } from 'react';
import { Row, Col } from 'react-grid-system';
import { connect } from 'react-redux';
import {
  Card,
  InputDate,
  PromptText,
  ExtraSmallText,
  WeekButtonGroup,
  Button,
  ScrollWrapper,
  DarkText,
  Input,
  Divider,
  H4,
  OptionButtonGroup,
  Page,
  PageHeader,
  MenuOptionButton,
  PageDetailHeader,
  MenuMainTitle,
  MenuSubtitleText,
} from 'listertech-commons-web';

import validate from 'util/ValidationWrapper';
import type { RegisterAgencyDto } from 'api/agencies/types';
import RegisterForm from 'pages/RegisterPage/RegisterForm';
import { confirmAlert } from 'react-confirm-alert';

const formValidationRules = {
  contact_email: {
    presence: {
      message: '^Email is required',
      allowEmpty: false,
    },
  },
  password: {
    presence: {
      message: '^Password is required',
      allowEmpty: false,
    },
  },
  retypepassword: {
    equality: {
      attribute: 'password',
      message: '^Passwords do not match',
    },
  },
  contact_name: {
    presence: {
      message: '^Name is required',
      allowEmpty: false,
    },
  },
  contact_phone: {
    presence: {
      message: '^Phone is required',
      allowEmpty: false,
    },
  },
  contact_ext: {
    presence: {
      message: '^Extension is required',
      allowEmpty: true,
    },
  },
  name: {
    presence: {
      message: '^Name is required',
      allowEmpty: false,
    },
  },
  phone: {
    presence: {
      message: '^Phone is required',
      allowEmpty: false,
    },
  },
  street_address: {
    presence: {
      message: '^Address is required',
      allowEmpty: false,
    },
  },
  // suite_number: {
  //   presence: {
  //     message: '^Suite number is required',
  //     allowEmpty: true,
  //   },
  // },
  city: {
    presence: {
      message: '^City is required',
      allowEmpty: false,
    },
  },
  state: {
    presence: {
      message: '^State is required',
      allowEmpty: false,
    },
  },
  zip: {
    presence: {
      message: '^Zipcode is required',
      allowEmpty: false,
    },
  },
  // bank_routing_number: {
  //   presence: {
  //     message: '^Routing number is required',
  //     allowEmpty: true,
  //   },
  // },
  // bank_account_number: {
  //   presence: {
  //     message: '^Account number is required',
  //     allowEmpty: true,
  //   },
  // },
  // bank_ein_number: {
  //   presence: {
  //     message: '^EIN number is required',
  //     allowEmpty: true,
  //   },
  // },
  director_name: {
    presence: {
      message: '^Director name is required',
      allowEmpty: false,
    },
  },
  director_email: {
    presence: {
      message: '^Director email is required',
      allowEmpty: false,
    },
  },
  director_phone: {
    presence: {
      message: '^Phone is required',
      allowEmpty: false,
    },
  },
  director_ext: {
    presence: {
      message: '^Extension is required',
      allowEmpty: true,
    },
  },
  cms_certification_number: {
    presence: {
      message: '^Certification is required',
      allowEmpty: false,
    },
  },
  cms_certification_expired_at: {
    presence: {
      message: '^Expiration is required',
      allowEmpty: false,
    },
  },
  business_license_number: {
    presence: {
      message: '^License number is required',
      allowEmpty: false,
    },
  },
  business_license_expired_at: {
    presence: {
      message: '^Expiration is required',
      allowEmpty: false,
    },
  },
  ahca_number: {
    presence: {
      message: '^Number is required',
      allowEmpty: false,
    },
  },
  ahca_expired_at: {
    presence: {
      message: '^Expiration is required',
      allowEmpty: false,
    },
  },
  // default_visit_rate: {
  //   presence: {
  //     message: '^Rate is required',
  //     allowEmpty: true,
  //   },
  // },
  // default_dc_rate: {
  //   presence: {
  //     message: '^Rate is required',
  //     allowEmpty: true,
  //   },
  // },
  // default_eval_rate: {
  //   presence: {
  //     message: '^Rate is required',
  //     allowEmpty: true,
  //   },
  // },
  // default_oasis_soc_rate: {
  //   presence: {
  //     message: '^Rate is required',
  //     allowEmpty: true,
  //   },
  // },
  // default_oasis_doc_rate: {
  //   presence: {
  //     message: '^Rate is required',
  //     allowEmpty: true,
  //   },
  // },
  // default_oasis_dc_rate: {
  //   presence: {
  //     message: '^Rate is required',
  //     allowEmpty: true,
  //   },
  // },
  // default_oasis_rechart_rate: {
  //   presence: {
  //     message: '^Rate is required',
  //     allowEmpty: true,
  //   },
  // },
  // default_visit_pta_rate: {
  //   presence: {
  //     message: '^Rate is required',
  //     allowEmpty: true,
  //   },
  // },
  // default_reeval_rate: {
  //   presence: {
  //     message: '^Rate is required',
  //     allowEmpty: true,
  //   },
  // },
  medical_week_start: {
    presence: {
      message: '^Week Start is required',
      allowEmpty: false,
    },
  },
  medial_week_end: {
    presence: {
      message: '^Week End is required',
      allowEmpty: false,
    },
  },
};

class RegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      contact_email: '',
      password: '',
      retypepassword: '',
      contact_name: '',
      contact_phone: '',
      contact_ext: '',
      name: '',
      phone: '',
      street_address: '',
      suite_number: null,
      city: '',
      state: '',
      zip: null,
      bank_routing_number: null,
      bank_account_number: null,
      bank_ein_number: null,
      director_name: '',
      director_email: '',
      director_phone: '',
      director_ext: '',
      cms_certification_number: null,
      cms_certification_expired_at: '',
      business_license_number: null,
      business_license_expired_at: '',
      ahca_number: null,
      ahca_expired_at: '',
      default_visit_rate: null,
      default_dc_rate: null,
      default_eval_rate: null,
      default_oasis_soc_rate: null,
      default_oasis_doc_rate: null,
      default_oasis_dc_rate: null,
      default_oasis_rechart_rate: null,
      default_visit_pta_rate: null,
      default_reeval_rate: null,
      medical_week_start: '',
      medial_week_end: '',
      formErrors: {
        contact_email: '',
        password: '',
        retypepassword: '',
        contact_name: '',
        contact_phone: '',
        contact_ext: '',
        name: '',
        phone: '',
        street_address: '',
        suite_number: null,
        city: '',
        state: '',
        zip: null,
        bank_routing_number: '',
        bank_account_number: '',
        bank_ein_number: '',
        director_name: '',
        director_email: '',
        director_phone: '',
        director_ext: '',
        cms_certification_number: null,
        cms_certification_expired_at: '',
        business_license_number: null,
        business_license_expired_at: '',
        ahca_number: null,
        ahca_expired_at: '',
        default_visit_rate: '',
        default_dc_rate: '',
        default_eval_rate: '',
        default_oasis_soc_rate: '',
        default_oasis_doc_rate: '',
        default_oasis_dc_rate: '',
        default_oasis_rechart_rate: '',
        default_visit_pta_rate: '',
        default_reeval_rate: '',
        medical_week_start: '',
        medial_week_end: '',
      },
      formValid: false,
      updateButtons: [
        {
          Title: 'M',
          value: 'M',
        },
        {
          Title: 'T',
          value: 'T',
        },
        {
          Title: 'W',
          value: 'W',
        },
        {
          Title: 'Th',
          value: 'Th',
        },
        {
          Title: 'F',
          value: 'F',
        },
        {
          Title: 'S',
          value: 'S',
        },
        {
          Title: 'Su',
          value: 'Su',
        },
      ],
    };
  }

  handleUserInput = (e, field) => {
    let name, value;

    if (field) {
      name = field;
      value = e;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    this.setState({
      [name]: value,
      formErrors: {
        ...this.state.formErrors,
        [name]: '',
      },
    });
  };

  validatePasswordField(value) {
    if (!value) {
      return 'Password is required';
    }
    return null;
  }

  validateEmailField(value) {
    if (!value) {
      return 'Email is required';
    }
    let emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if (!emailValid) {
      return 'Email is invalid';
    }
    return null;
  }

  validateRetypePasswordField(value) {
    if (!value) {
      return 'Password is required';
    }
    return null;
  }

  handleSubmit = () => {
    this.validateForm(data => {
      this.props.register(data, this.onRegisterSuccess);
    });
  };

  onRegisterSuccess = () => {
    this.handleBack();
    confirmAlert({
      title: 'Success',
      message: 'Account created successfully',
      buttons: [
        {
          label: 'Ok',
          onClick: () => {},
        },
      ]
    });
  };

  validateForm = (onSuccess): Object => {
    let {
      contact_email,
      password,
      retypepassword,
      contact_name,
      contact_phone,
      contact_ext,
      name,
      phone,
      street_address,
      suite_number,
      city,
      state,
      zip,
      bank_routing_number,
      bank_account_number,
      bank_ein_number,
      director_name,
      director_email,
      director_phone,
      director_ext,
      cms_certification_number,
      cms_certification_expired_at,
      business_license_number,
      business_license_expired_at,
      ahca_number,
      ahca_expired_at,
      default_visit_rate,
      default_dc_rate,
      default_eval_rate,
      default_oasis_soc_rate,
      default_oasis_doc_rate,
      default_oasis_dc_rate,
      default_oasis_rechart_rate,
      default_visit_pta_rate,
      default_reeval_rate,
      medical_week_start,
      medial_week_end,
    } = this.state;

    let data: RegisterAgencyDto = {
      contact_email,
      password,
      retypepassword,
      contact_name,
      contact_phone,
      contact_ext,
      name,
      phone,
      street_address,
      suite_number,
      city,
      state,
      zip,
      // bank_routing_number,
      // bank_account_number,
      // bank_ein_number,
      director_name,
      director_email,
      director_phone,
      director_ext,
      cms_certification_number,
      cms_certification_expired_at,
      business_license_number,
      business_license_expired_at,
      ahca_number,
      ahca_expired_at,
      // default_visit_rate,
      // default_dc_rate,
      // default_eval_rate,
      // default_oasis_soc_rate,
      // default_oasis_doc_rate,
      // default_oasis_dc_rate,
      // default_oasis_rechart_rate,
      // default_visit_pta_rate,
      // default_reeval_rate,
      medical_week_start,
      medial_week_end,
    };

    let valerror = validate(data, formValidationRules);

    if (valerror) {
      if (Object.keys(valerror).length && valerror.retypepassword) {
        alert('Passwords do not match.');
      } else {
        alert('Please fill out all the fields.');
      }

      this.setState({
        formErrors: {
          ...this.state.formErrors,
          ...valerror,
        },
      });
    } else {
      onSuccess(data);
    }
  };

  handleStartChange = value => {
    this.setState({
      medical_week_start: value,
    });
  };

  handleEndChange = value => {
    this.setState({
      medial_week_end: value,
    });
  };

  errorClass(error) {
    return error.length === 0 ? '' : 'has-error';
  }

  handleBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { updateButtons } = this.state;

    return (
      <Page>
        <Row>
          <div>
            <MenuMainTitle style={{ width: 10 }}>Visitry</MenuMainTitle>
            <MenuSubtitleText>Agency Registration</MenuSubtitleText>
          </div>
          <Card style={{ paddingTop: 0, paddingLeft: 30 }}>
            <RegisterForm onSubmit={e => e.preventDefault()}>
              <Row justify="space-between" align="center">
                <Col>
                  <PageHeader>Agency Registration</PageHeader>
                </Col>
                <Col>
                  <OptionButtonGroup>
                    <MenuOptionButton onClick={this.handleBack}>
                      Login
                    </MenuOptionButton>
                    <MenuOptionButton onClick={this.handleSubmit}>
                      Register
                    </MenuOptionButton>
                    <MenuOptionButton onClick={this.handleBack}>
                      Cancel
                    </MenuOptionButton>
                  </OptionButtonGroup>
                </Col>
              </Row>
              <Divider />
              <ScrollWrapper>
                <PageDetailHeader>General Info</PageDetailHeader>
                <Row>
                  <Col sm={3}>
                    <H4>Agency Name</H4>
                    <Input
                      type="name"
                      refs="name"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.name)}
                      error={this.state.formErrors.name}
                    />
                  </Col>
                  <Col sm={3}>
                    <H4>Agency Phone</H4>
                    <Input
                      type="phone"
                      refs="phone"
                      name="phone"
                      value={this.state.phone}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.phone)}
                      error={this.state.formErrors.phone}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <H4>Street Address</H4>
                    <Input
                      type="street_address"
                      refs="street_address"
                      name="street_address"
                      value={this.state.street_address}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.street_address)}
                      error={this.state.formErrors.street_address}
                    />
                  </Col>
                  <Col sm={1.5}>
                    <H4>Ste #</H4>
                    <Input
                      type="suite_number"
                      refs="suite_number"
                      name="suite_number"
                      value={this.state.suite_number}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.suite_number)}
                      error={this.state.formErrors.suite_number}
                    />
                  </Col>
                  <Col sm={3}>
                    <H4>City</H4>
                    <Input
                      type="city"
                      refs="city"
                      name="city"
                      value={this.state.city}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.city)}
                      error={this.state.formErrors.city}
                    />
                  </Col>
                  <Col sm={1.5}>
                    <H4>State</H4>
                    <Input
                      type="state"
                      refs="state"
                      name="state"
                      value={this.state.state}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.state)}
                      error={this.state.formErrors.state}
                    />
                  </Col>
                  <Col sm={2}>
                    <H4>Zip</H4>
                    <Input
                      type="zip"
                      refs="zip"
                      name="zip"
                      value={this.state.zip}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.zip)}
                      error={this.state.formErrors.zip}
                    />
                  </Col>
                </Row>
                <PageDetailHeader>Create Password</PageDetailHeader>
                <Row>
                  <Col sm={3}>
                    <H4>Type Password</H4>
                    <Input
                      type="password"
                      refs="password"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.password)}
                      error={this.state.formErrors.password}
                    />
                  </Col>
                  <Col sm={3}>
                    <H4>Re-type Password</H4>
                    <Input
                      type="password"
                      refs="retypepassword"
                      name="retypepassword"
                      value={this.state.retypepassword}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.retypepassword)}
                      error={this.state.formErrors.retypepassword}
                    />
                  </Col>
                </Row>
                <PageDetailHeader>Primary Contact</PageDetailHeader>
                <Row>
                  <Col sm={3}>
                    <H4>Name</H4>
                    <Input
                      placeholder="First & Last Name"
                      type="contact_name"
                      refs="contact_name"
                      name="contact_name"
                      value={this.state.contact_name}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.contact_name)}
                      error={this.state.formErrors.contact_name}
                    />
                  </Col>
                  <Col sm={3}>
                    <H4>Email</H4>
                    <Input
                      type="contact_email"
                      refs="contact_email"
                      name="contact_email"
                      value={this.state.contact_email}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.contact_email)}
                      error={this.state.formErrors.contact_email}
                    />
                  </Col>
                  <Col sm={3}>
                    <H4>Phone</H4>
                    <Input
                      type="contact_phone"
                      refs="contact_phone"
                      name="contact_phone"
                      value={this.state.contact_phone}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.contact_phone)}
                      error={this.state.formErrors.contact_phone}
                    />
                  </Col>
                  <Col sm={1}>
                    <H4>Ext</H4>
                    <Input
                      type="contact_ext"
                      refs="contact_ext"
                      name="contact_ext"
                      value={this.state.contact_ext}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.contact_ext)}
                      error={this.state.formErrors.contact_ext}
                    />
                  </Col>
                </Row>
                <PageDetailHeader>
                  Director of Clinical Services
                </PageDetailHeader>
                <Row>
                  <Col sm={3}>
                    <H4>Name</H4>
                    <Input
                      placeholder="First & Last Name"
                      type="director_name"
                      refs="director_name"
                      name="director_name"
                      value={this.state.director_name}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.director_name)}
                      error={this.state.formErrors.director_name}
                    />
                  </Col>
                  <Col sm={3}>
                    <H4>Email</H4>
                    <Input
                      type="director_email"
                      refs="director_email"
                      name="director_email"
                      value={this.state.director_email}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.director_email)}
                      error={this.state.formErrors.director_email}
                    />
                    <H4>Agency emails will be sent to this address</H4>
                  </Col>
                  <Col sm={3}>
                    <H4>Phone</H4>
                    <Input
                      type="director_phone"
                      refs="director_phone"
                      name="director_phone"
                      value={this.state.director_phone}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.director_phone)}
                      error={this.state.formErrors.director_phone}
                    />
                  </Col>
                  <Col sm={1}>
                    <H4>Ext</H4>
                    <Input
                      type="director_ext"
                      refs="director_ext"
                      name="director_ext"
                      value={this.state.director_ext}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.director_ext)}
                      error={this.state.formErrors.director_ext}
                    />
                  </Col>
                </Row>
                <PageDetailHeader>Licenses & Certifications</PageDetailHeader>
                <Row>
                  <Col sm={3}>
                    <H4>CMS Certification #</H4>
                    <Input
                      type="cms_certification_number"
                      refs="cms_certification_number"
                      name="cms_certification_number"
                      value={this.state.cms_certification_number}
                      onChange={this.handleUserInput}
                      isError={Boolean(
                        this.state.formErrors.cms_certification_number,
                      )}
                      error={this.state.formErrors.cms_certification_number}
                    />
                  </Col>
                  <Col sm={2}>
                    <H4>Expiration Date</H4>
                    <InputDate
                      placeholder="mm/dd/yyyy"
                      type="date"
                      refs="cms_certification_expired_at"
                      name="cms_certification_expired_at"
                      value={this.state.cms_certification_expired_at}
                      onDateChange={(e) => this.handleUserInput(e, 'cms_certification_expired_at')}
                      isError={Boolean(
                        this.state.formErrors.cms_certification_expired_at,
                      )}
                      error={this.state.formErrors.cms_certification_expired_at}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <H4>State Business License/Sunbiz #</H4>
                    <Input
                      type="business_license_number"
                      refs="business_license_number"
                      name="business_license_number"
                      value={this.state.business_license_number}
                      onChange={this.handleUserInput}
                      isError={Boolean(
                        this.state.formErrors.business_license_number,
                      )}
                      error={this.state.formErrors.business_license_number}
                    />
                  </Col>
                  <Col sm={2}>
                    <H4>Expiration Date</H4>
                    <InputDate
                      placeholder="mm/dd/yyyy"
                      type="date"
                      refs="business_license_expired_at"
                      name="business_license_expired_at"
                      value={this.state.business_license_expired_at}
                      onDateChange={(e) => this.handleUserInput(e, 'business_license_expired_at')}
                      isError={Boolean(
                        this.state.formErrors.business_license_expired_at,
                      )}
                      error={this.state.formErrors.business_license_expired_at}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <H4>AHCA #</H4>
                    <Input
                      type="ahca_number"
                      refs="ahca_number"
                      name="ahca_number"
                      value={this.state.ahca_number}
                      onChange={this.handleUserInput}
                      isError={Boolean(this.state.formErrors.ahca_number)}
                      error={this.state.formErrors.ahca_number}
                    />
                  </Col>
                  <Col sm={2}>
                    <H4>Expiration Date</H4>
                    <InputDate
                      placeholder="mm/dd/yyyy"
                      type="date"
                      refs="ahca_expired_at"
                      name="ahca_expired_at"
                      value={this.state.ahca_expired_at}
                      onDateChange={(e) => this.handleUserInput(e, 'ahca_expired_at')}
                      isError={Boolean(this.state.formErrors.ahca_expired_at)}
                      error={this.state.formErrors.ahca_expired_at}
                    />
                  </Col>
                </Row>
                <PageDetailHeader>Medicare Week</PageDetailHeader>
                <Row>
                  <Col>
                    <DarkText>
                      Click on the day that your Medicare week "Begins" and when
                      it "ends" on days of the week below.
                    </DarkText>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ExtraSmallText>Start</ExtraSmallText>
                    <WeekButtonGroup
                      isHorizontal
                      buttons={updateButtons}
                      value={this.state.medical_week_start}
                      onChange={this.handleStartChange}
                    />
                  </Col>
                  <Col>
                    <ExtraSmallText>End</ExtraSmallText>
                    <WeekButtonGroup
                      isHorizontal
                      buttons={updateButtons}
                      value={this.state.medial_week_end}
                      onChange={this.handleEndChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: '20px' }}>
                    <PromptText>{this.props.error}</PromptText>
                    <Button
                      theme="dark"
                      onClick={this.handleSubmit}
                      loading={this.props.loading}
                    >
                      Register
                    </Button>
                  </Col>
                </Row>
              </ScrollWrapper>
            </RegisterForm>
          </Card>
        </Row>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.loading['REGISTER'],
  error: state.error['REGISTER'],
});

const mapDispatchToProps = dispatch => ({
  register: (data, onSuccess) =>
    dispatch({
      type: 'REGISTER',
      data,
      onSuccess,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
