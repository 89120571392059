export const patientValidationRules = {
  name: {
    presence: {
      message: '^Name is required',
      allowEmpty: false,
    },
  },
  address: {
    presence: {
      message: '^Street address is required',
      allowEmpty: false,
    },
  },
  city: {
    presence: {
      message: '^City is required',
      allowEmpty: false,
    },
  },
  state: {
    presence: {
      message: '^State is required',
      allowEmpty: false,
    },
  },
  zip: {
    presence: {
      message: '^Zip is required',
      allowEmpty: false,
    },
  },
};

export const managerValidationRules = {};

export const doctorValidationRules = {};

export const contractorValidationRules = {
  name: {
    equality: {
      attribute: 'id',
      message:
        '^Please select a contractor from the list or leave the name empty',
      comparator: (v1: string, v2: string) => {
        return (!Boolean(v1) && !Boolean(v2)) || (Boolean(v1) && Boolean(v2));
      },
    },
  },
};

export const formValidationRules = {
  typeDiscepline: {
    presence: {
      message: '^Discipline is required',
      allowEmpty: false,
    },
  },
  typeValue: {
    presence: {
      message: '^Type is required',
      allowEmpty: false,
    },
  },
  date: {
    presence: {
      message: '^Date is required',
      allowEmpty: false,
    },
  },
};
