/* @flow */
import { call, put, takeLatest, select } from 'redux-saga/effects';
import type { Visit } from 'data/visits';
import type {
  VisitDto,
  CreateVisitDto,
  UpdateVisitDto,
} from 'api/visits/types';
import {
  callGetVisits,
  callAddVisit,
  callEditVisit,
  callVerifyVisit,
  callRejectVisit,
  callCancelVisit,
  callGetVisit,
} from 'api/visits';
import { VisitsConverter } from 'api/visits/converters';
import VisitsActions from 'data/visits/actions';
import PaginationActions from 'data/pagination/actions';
import { PAGE_SIZE } from 'data/pagination/config';
import { callRemoveVisit } from '../api/visits';

export function* getVisits(action: {
  status: string,
  page: number,
  sort: string,
  desc: boolean,
  search?: string,
  filters?: string[],
}): Generator<Object, void, void> {
  try {
    yield put({ type: 'GET_VISITS_REQUEST' });
    const response: Object = yield call(
      callGetVisits,
      action.status,
      PAGE_SIZE,
      action.page,
      action.sort,
      action.desc,
      action.search,
      [...action.filters, ''],
    );
    const dtos = response.data;
    const entities = new VisitsConverter().toEntityArray(dtos);
    yield put(VisitsActions.putVisits(entities));
    yield put(
      PaginationActions.setPage(
        action.status.toLowerCase(),
        action.page,
        response.headers.count,
        entities.map(v => v.id),
      ),
    );
    yield put({ type: 'GET_VISITS_SUCCESS' });
  } catch (error) {
    yield put({
      type: 'GET_VISITS_FAILURE',
      error: error.message,
    });
  }
}

export function* getDashboardVisits(action: {
  status: string,
  page: number,
  sort: string,
  desc: boolean,
}): Generator<Object, void, void> {
  try {
    yield put({ type: 'GET_DASHBOARD_VISITS_REQUEST' });
    const response: Object = yield call(
      callGetVisits,
      '',
      true,
      0,
      'created_at',
      true,
    );
    const entities = new VisitsConverter().toEntityArray(response.data);
    yield put(VisitsActions.putDashboardVisits(entities));
    yield put({ type: 'GET_DASHBOARD_VISITS_SUCCESS' });
  } catch (error) {
    yield put({
      type: 'GET_DASHBOARD_VISITS_FAILURE',
      error: error.message,
    });
  }
}

export function* getVisit(action: {
  id: string,
}): Generator<Object, void, void> {
  try {
    yield put({ type: 'GET_VISIT_REQUEST' });
    const response: Object = yield call(
      callGetVisit,
      action.id,
    );
    const entities = new VisitsConverter().toEntityArray([response]);
    yield put(VisitsActions.putVisits(entities));
    yield put({ type: 'GET_VISIT_SUCCESS' });
  } catch (error) {
    yield put({
      type: 'GET_VISIT_FAILURE',
      error: error.message,
    });
  }
}

export function* addVisit(action: {
  data: CreateVisitDto,
  resolve: Function,
  reject: Function,
}): Generator<Object, void, void> {
  try {
    yield put({ type: 'ADD_VISIT_REQUEST' });
    const response: Object = yield call(callAddVisit, action.data);
    const converter = new VisitsConverter();
    yield put(VisitsActions.addVisit(converter.toEntity(response)));
    if (Boolean(action.data.contractor_id)) {
      yield put(PaginationActions.clearPages('accepted'));
    } else {
      yield put(PaginationActions.clearPages('open'));
    }
    yield put({ type: 'ADD_VISIT_SUCCESS' });
    action.resolve();
  } catch (error) {
    yield put({
      type: 'ADD_VISIT_FAILURE',
      error: error.message,
    });
    action.reject(error.message);
  }
}

export function* editVisit(action: {
  data: UpdateVisitDto,
  resolve: Function,
  reject: Function,
}): Generator<Object, void, void> {
  try {
    yield put({ type: 'EDIT_VISIT_REQUEST' });
    const response: Object = yield call(callEditVisit, action.data);
    const converter = new VisitsConverter();
    const entity = converter.toEntity(response);
    yield put(VisitsActions.updateVisit(entity));
    if (Boolean(action.data.contractor_id)) {
      yield put(PaginationActions.clearPages('open'));
      yield put(PaginationActions.clearPages('accepted'));
    }
    yield put({ type: 'EDIT_VISIT_SUCCESS' });
    action.resolve();
  } catch (error) {
    yield put({
      type: 'EDIT_VISIT_FAILURE',
      error: error.message,
    });
    action.reject(error.message);
  }
}

export function* verifyVisit(action: { id: string, onSuccess: () => void }): Generator<Object, void, void> {
  try {
    yield put({ type: 'VERIFY_VISIT_REQUEST' });
    const response: Object = yield call(callVerifyVisit, action.id);
    const page = yield select((state) => state.pagination.verified.currentPage || 0);
    const search = yield select((state) => state.visits.search);
    yield getVisits({
      status: "Pending",
      page,
      sort: 'created_at',
      desc: true,
      search,
    });
    yield put(VisitsActions.verifyVisit(action.id));
    yield put({ type: 'VERIFY_VISIT_SUCCESS' });

    if (action.onSuccess) {
      action.onSuccess();
    }

  } catch (error) {
    yield put({
      type: 'VERIFY_VISIT_FAILURE',
      error: error.message,
    });
  }
}

export function* rejectVisit(action: { id: string, onSuccess: () => void }): Generator<Object, void, void> {
  try {
    yield put({ type: 'REJECT_VISIT_REQUEST' });
    const response: Object = yield call(callRejectVisit, action.id);
    yield put(VisitsActions.cancelVisit(action.id));
    yield put(PaginationActions.takeFrom(action.id, "pending"));
    yield put(PaginationActions.clearPages("canceled"));
    yield put({ type: 'REJECT_VISIT_SUCCESS' });

    if (action.onSuccess) {
      action.onSuccess();
    }

  } catch (error) {
    yield put({
      type: 'REJECT_VISIT_FAILURE',
      error: error.message,
    });
  }
}

export function* cancelVisit(action: { id: string, tab:string}): Generator<Object, void, void> {
  try {
    yield put({ type: 'CANCEL_VISIT_REQUEST' });
    const response: Object = yield call(callCancelVisit, action.id);
    yield put(VisitsActions.cancelVisit(action.id));
    yield put(PaginationActions.takeFrom(action.id, action.tab));
    yield put(PaginationActions.clearPages("canceled"));
    yield put({ type: 'CANCEL_VISIT_SUCCESS' });
  } catch (error) {
    yield put({
      type: 'CANCEL_VISIT_FAILURE',
      error: error.message,
    });
  }
}

export function* removeVisit(action: { tab: string, id: string }): Generator<Object, void, void> {
  try {
    yield put({ type: 'REMOVE_VISIT_REQUEST' });
    const response: Object = yield call(callRemoveVisit, action.id);
    yield put(VisitsActions.removeVisit(action.id));
    yield put(PaginationActions.takeFrom(action.id, action.tab));
    yield put({ type: 'REMOVE_VISIT_SUCCESS' });
  } catch (error) {
    yield put({
      type: 'REMOVE_VISIT_FAILURE',
      error: error.message,
    });
  }
}

const visitsSaga = [
  takeLatest('GET_VISITS', getVisits),
  takeLatest('GET_VISIT', getVisit),
  takeLatest('GET_DASHBOARD_VISITS', getDashboardVisits),
  takeLatest('ADD_VISIT', addVisit),
  takeLatest('EDIT_VISIT', editVisit),
  takeLatest('VERIFY_VISIT', verifyVisit),
  takeLatest('REJECT_VISIT', rejectVisit),
  takeLatest('CANCEL_VISIT', cancelVisit),
  takeLatest('REMOVE_VISIT', removeVisit),
];

export default visitsSaga;
