import * as ActionTypes from './actionTypes';

const initialState = {
  all: [],
};

export default function documents(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_DOCUMENTS:
      return {
        ...state,
        all: action.documents,
      };
    default:
      return state;
  }
}
