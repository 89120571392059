/* @flow */
import React from 'react';
import moment from 'moment';
import { DataTable, ListLargeText, ActionButton } from 'listertech-commons-web';
import type { Visit } from 'data/visits';

type Props = {
  data: Visit[],
  onVerify: (id: string) => void,
  onReject: (id: string) => void,
};

const PendingTable = (props: Props) => {
  return (
    <DataTable
      name="pending-visits"
      headers={[
        { title: 'Patient Name', size: { sm: 3 }},
        { title: 'Date', size: { sm: 2 }},
        { title: 'Type', size: { sm: 3 }},
        { title: 'Action', size: { sm: 4 }},
      ]}
      data={props.data.slice(0, 5)}
      renderers={[
        (v: Visit) => <ListLargeText>{v.patient.name}</ListLargeText>,
        (v: Visit) => <ListLargeText>{moment(v.date).format('MM/DD')}</ListLargeText>,
        (v: Visit) => <ListLargeText>{v.type}</ListLargeText>,
        (v: Visit) => (
          <>
            <ActionButton
              green
              onClick={(e) => {
                e.preventDefault();
                props.onVerify(v.id)
              }}
              key={`pending-visits-verify-${v.id}`}
            >
              Verify
            </ActionButton>
            <ActionButton
              red
              onClick={(e) => {
                e.preventDefault();
                props.onReject(v.id)
              }}
              key={`pending-visits-reject-${v.id}`}
            >
              Reject
            </ActionButton>
          </>
        ),
      ]}
      onSort={() => {}}
    />
  );
};

export default PendingTable;
