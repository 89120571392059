/* @flow */
import React from 'react';
import { DataTable, ListLargeText, ActionButton } from 'listertech-commons-web';
import type { Visit } from 'data/visits';

type Props = {
  data: Visit[],
  onDetails: (id: string) => void,
};

const AcceptedTable = (props: Props) => {
  return (
    <DataTable
      name="accepted-visits"
      headers={[
        { title: 'Patient Name', size: { xs: 3 }},
        { title: 'Type', size: { xs: 3 }},
        { title: 'Clinician', size: { xs: 3 }},
        { title: 'Action', size: { xs: 3, md: 2 }},
      ]}
      data={props.data.slice(0, 5)}
      renderers={[
        (v: Visit) => <ListLargeText>{v.patient.name}</ListLargeText>,
        (v: Visit) => <ListLargeText>{v.type}</ListLargeText>,
        (v: Visit) => (
          <ListLargeText>{v.contractor ? v.contractor.name : ''}</ListLargeText>
        ),
        (v: Visit) => (
          <React.Fragment>
            <ActionButton
              onClick={(e) => {
                e.preventDefault();
                props.onDetails(v.id);
              }}
              key={`accepted-visits-details-${v.id}`}
            >
              Details
            </ActionButton>
          </React.Fragment>
        ),
      ]}
      onSort={() => {}}
    />
  );
};

export default AcceptedTable;
