import React from 'react';
import styled from 'styled-components';
import {PageHeader,PageDetailHeader,Field} from 'listertech-commons-web';
import SideNavPage from 'components/SideNavPage';
import { Col, Row } from 'react-grid-system';

import { Images } from 'listertech-commons-web';

const BlockContainer = styled.div`
  height: 100%;
  border-radius: 10px;
  background-color: #F7F8F8;
  padding: ${({large}) => large ? 40 : 20}px;

  ${({ large }) => large ? `
    @media (max-width: 991px) {
      display: none;
    }
  ` : ''}
`;

const QuestionContainer = styled.div`
  & + & {
    margin-top: 10px;
  }
`;

const QuestionCard = styled.div`
  border-radius: 20px;
  padding: 20px;
  flex-grow: 1;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  background-color: #FFFFFF;
  color: #354148;

  ${({ active }) => active ? `
    background-color: #354148;
    color: #FFFFFF;
  `: ''}
`;

const Answer = styled.p`
  color: #788F9B;
  font-family: Montserrat;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;

  ${({ active }) => active !== undefined ? `
    padding: 0 20px;
    margin-bottom: 20px;

    @media (min-width: 992px) {
      display: none;
    }

  ` : ''}

  ${({ active }) => active === false ? `
    display: none;
  ` : ''}

  ${({ active }) => active === true ? `
    display: block;
  ` : ''}
`;

const SocialLinks = styled.div`
  img {
    max-width: 17px;
    max-height: 17px;
    margin-right: 15px;
  }
`;

const questions = [
  {
    question: 'Lorem ipsum dolor sit amet, duis adipiscing elit?',
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. \n' +
      '\n' +
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. \n' +
      '\n' +
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
  },
  {
    question: 'Lorem ipsum , duis adipiscing elit?',
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. \n' +
      '\n' +
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
  },
  {
    question: 'Lorem ipsum dolor sit amet?',
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. \n' +
      '\n' +
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. \n'
  },
]

class HelpPage extends React.Component {
  state = { activeCardIndex: 0 };

  setActiveCardIndex = (index) => {
    this.setState({ activeCardIndex: index });
  }

  renderSocialBlock = () => (
    <SocialLinks>
      <a href="https://twitter.com">
        <img src={Images.twitter} alt="Twitter" />
      </a>
      <a href="https://facebook.com">
        <img src={Images.facebook} alt="Facebook" />
      </a>
      <a href="https://linkedin.com">
        <img src={Images.linkedin} alt="Linkedin" />
      </a>
    </SocialLinks>
  )

  render() {
    const activeQuestion = questions[this.state.activeCardIndex];

    return (
      <SideNavPage>
        <PageHeader underlined>Help Center</PageHeader>
        <PageDetailHeader>Contact Us</PageDetailHeader>
        <Row>
          <Col sm={6} md={4} lg={3} xl={2}>
            <Field label="Email" value="support@visitry.com" />
          </Col>
          <Col sm={6} md={4} lg={3} xl={2}>
            <Field label="Phone" value="(800) 123-4567" />
          </Col>
          <Col sm={6} md={4} lg={3} xl={2}>
            <Field label="Social Media" value={this.renderSocialBlock()} />
          </Col>
        </Row>
        <PageDetailHeader>FAQ</PageDetailHeader>
        <Row>
          <Col md={12} lg={5}>
            <BlockContainer>
              {questions.map((question, index) => (
                <QuestionContainer>
                  <QuestionCard
                    onClick={() => this.setActiveCardIndex(index)}
                    active={index === this.state.activeCardIndex}
                  >{question.question}
                  </QuestionCard>
                  <Answer
                    active={index === this.state.activeCardIndex}
                  >{question.answer}
                  </Answer>
                </QuestionContainer>
              ))}
            </BlockContainer>
          </Col>
          <Col md={7}>
            <BlockContainer large>
              <Answer>{activeQuestion.answer}</Answer>
            </BlockContainer>
          </Col>
        </Row>
      </SideNavPage>
    );
  }
}

export default HelpPage;
