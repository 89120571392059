/* @flow */
import * as ActionTypes from './actionTypes';
import type { Contractor, ContractorsState } from './index';
import type { ContractorsActions } from './actionTypes';

const initialState: ContractorsState = {
  all: [],
};

export default function messages(
  state: ContractorsState = initialState,
  action: ContractorsActions,
): ContractorsState {
  switch (action.type) {
    case ActionTypes.SET_CONTRACTORS:
      return {
        ...state,
        all: action.contractors.map(c => {
          let enrollment = c.agencies.find(e => e.id === action.agencyId);
          return {
            ...c,
            enrollment: enrollment ? enrollment.state : null,
          };
        }),
      };
    case ActionTypes.ENROLL_CONTRACTOR:
      return {
        ...state,
        all: state.all.map(c =>
          c.id === action.id ? { ...c, enrollment: 'accepted' } : c,
        ),
      };
    case ActionTypes.REJECT_CONTRACTOR:
      return {
        ...state,
        all: state.all.map(c =>
          c.id === action.id ? { ...c, enrollment: 'rejected' } : c,
        ),
      };
    case ActionTypes.REMOVE_CONTRACTOR:
      return {
        ...state,
        all: state.all.filter(contractor => contractor.id !== action.id)
      }
    case 'RESET':
      return {
        ...initialState,
      }
    default:
      return state;
  }
}
