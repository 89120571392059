import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import LoginPage from 'pages/LoginPage';
import RegisterPage from 'pages/RegisterPage';
import DashboardPage from 'pages/DashboardPage';
import VisitsPage from 'pages/VisitsPage';
import VisitDetailsPage from 'pages/VisitDetailsPage';
import AddVisitPage from 'pages/AddVisitPage';
import ContractorsPage from 'pages/ContractorsPage';
import ContractorDetailsPage from 'pages/ContractorDetailsPage';
import AccountPage from 'pages/AccountPage';
import HelpPage from 'pages/HelpPage';
import AllDocumentsPage from 'pages/AllDocumentsPage';
import GlobalStyle from './GlobalStyle';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import DeleteAccountPage from 'pages/DeleteAccountPage';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import { Helmet } from 'react-helmet';

const AuthRoute = ({
  component: Component,
  title,
  isAuthenticated,
  ...rest
}) => (
  <>
    <Helmet>
      <title> {title} </title>
    </Helmet>

    <Route
      {...rest}
      render={props =>
        isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login' }} />
        )
      }
    />
  </>
);

const UnauthRoute = ({
  component: Component,
  title,
  isAuthenticated,
  ...rest
}) => (
  <>
    <Helmet>
      <title> {title} </title>
    </Helmet>
    <Route
      {...rest}
      render={props =>
        isAuthenticated === false ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/' }} />
        )
      }
    />
  </>
);

const Routes = props => (
  <div>
    <GlobalStyle />
    <Switch>
      <UnauthRoute
        path="/login"
        title="Visitry | Login"
        exact
        component={LoginPage}
        isAuthenticated={props.isAuthenticated}
      />
      <UnauthRoute
        path="/register"
        title="Visitry | Register"
        exact
        component={RegisterPage}
        isAuthenticated={props.isAuthenticated}
      />
      <UnauthRoute
        path="/forgot"
        title="Visitry | Password Reset"
        exact
        component={ForgotPasswordPage}
        isAuthenticated={props.isAuthenticated}
      />
      <UnauthRoute
        path="/reset/:token"
        title="Visitry | Password Reset"
        exact
        component={ResetPasswordPage}
        isAuthenticated={props.isAuthenticated}
      />
      <UnauthRoute
        path="/remove"
        title="Visitry | Delete Account"
        exact
        component={DeleteAccountPage}
        isAuthenticated={props.isAuthenticated}
      />
      <AuthRoute
        path="/"
        title="Visitry | Dashboard"
        exact
        component={DashboardPage}
        isAuthenticated={props.isAuthenticated}
      />
      <AuthRoute
        path="/visits"
        title="Visitry | Visits"
        exact
        component={VisitsPage}
        isAuthenticated={props.isAuthenticated}
      />
      <AuthRoute
        path="/visits/add"
        title="Visitry | Visits"
        exact
        component={AddVisitPage}
        isAuthenticated={props.isAuthenticated}
      />
      <AuthRoute
        path="/visits/edit"
        title="Visitry | Visits"
        exact
        component={AddVisitPage}
        isAuthenticated={props.isAuthenticated}
      />
      <AuthRoute
        path="/visits/:visitId"
        title="Visitry | Visits"
        component={VisitDetailsPage}
        isAuthenticated={props.isAuthenticated}
      />
      <AuthRoute
        path="/clinicians"
        title="Visitry | Clinicians"
        exact
        component={ContractorsPage}
        isAuthenticated={props.isAuthenticated}
      />
      <AuthRoute
        path="/clinicians/:contractorId"
        title="Visitry | Clinicians"
        component={ContractorDetailsPage}
        isAuthenticated={props.isAuthenticated}
      />
      <AuthRoute
        path="/help"
        title="Visitry | Help"
        exact
        component={HelpPage}
        isAuthenticated={props.isAuthenticated}
      />
      <AuthRoute
        path="/account"
        title="Visitry | Account"
        exact
        component={AccountPage}
        isAuthenticated={props.isAuthenticated}
      />
      <AuthRoute
        path="/documents/:contractorId"
        title="Visitry | Documents"
        exact
        component={AllDocumentsPage}
        isAuthenticated={props.isAuthenticated}
      />
    </Switch>
  </div>
);

const mapStateToProps = state => ({
  isAuthenticated:
    Boolean(state.session.accessToken) && state.session.expire >= Date.now(),
});

export default connect(mapStateToProps)(Routes);
