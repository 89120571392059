/* @flow */
import type { VisitDto, PatientDto } from './types';
import type { Visit, Patient } from 'data/visits';
import Converter from '../converter';

export class PatientConverter extends Converter<PatientDto, Patient> {
  toEntity(dto: PatientDto): Patient {
    return {
      name: dto.name,
      mr: dto.mr_number,
      gender: dto.gender,
      dob: dto.dob ? new Date(dto.dob) : null,
      age: dto.age,
      diagnosis: dto.diagnosis,
      phone: dto.phone,
      emergencyContact: dto.emergency_contact,
      address: dto.address,
      apartment: dto.apartment,
      city: dto.city,
      state: dto.state,
      zip: dto.zip,
      visits: dto.visits,
    };
  }
}

export class VisitsConverter extends Converter<VisitDto, Visit> {
  toEntity(dto: VisitDto): Visit {
    return {
      id: dto._id,
      price: dto.price,
      type: dto.type,
      region: dto.region,
      agencyName: dto.agency.name,
      agencyId: dto.agency_id,
      lat: dto.lat,
      lng: dto.lng,
      date: new Date(dto.date),
      status: dto.status,
      patient: new PatientConverter().toEntity(dto.patient),
      doctor: {
        name: dto.doctor.name,
        phone: dto.doctor.phone,
        ext: dto.doctor.ext,
      },
      manager: {
        id: dto.case_manager_id,
        name: dto.case_manager ? dto.case_manager.name : '',
        phone: dto.case_manager_phone,
        ext: dto.case_manager_ext,
      },
      contractor: dto.contractor
        ? {
            id: dto.contractor._id,
            name: dto.contractor.name,
            phone: dto.contractor.phone,
          }
        : null,
      views: dto.views || 0,
      submission: dto.submission
        ? {
            acceptedDate: new Date(dto.submission.timestamp_accepted),
            submittedDate: new Date(dto.submission.timestamp_submitted),
            canceledDate: new Date(dto.submission.timestamp_canceled),
            paidDate: new Date(dto.submission.timestamp_paid),
            reasonCanceled: dto.submission.reason_canceled,
          }
        : null,
    };
  }
}
