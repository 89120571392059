/* @flow */
import * as ActionTypes from './actionTypes';
import type { Contractor } from './index';

const setContractors = (contractors: Contractor[], agencyId: string) => ({
  type: ActionTypes.SET_CONTRACTORS,
  contractors,
  agencyId,
});

const enrollContractor = (id: string) => ({
  type: ActionTypes.ENROLL_CONTRACTOR,
  id,
});

const rejectContractor = (id: string) => ({
  type: ActionTypes.REJECT_CONTRACTOR,
  id,
});

const removeContractor = (id: string) => ({
  type: ActionTypes.REMOVE_CONTRACTOR,
  id,
})

export default {
  setContractors,
  enrollContractor,
  rejectContractor,
  removeContractor
};
