/* @flow */
import type { Contractor } from './index';

export const SET_CONTRACTORS = 'contractors/SET_CONTRACTORS';
export const ENROLL_CONTRACTOR = 'contractors/ENROLL_CONTRACTOR';
export const REJECT_CONTRACTOR = 'contractors/REJECT_CONTRACTOR';
export const REMOVE_CONTRACTOR = 'contractors/REMOVE_CONTRACTOR';

export type SetContractorsAction = {
  type: typeof SET_CONTRACTORS,
  contractors: Contractor[],
  agencyId: string,
};

export type EnrollContractorAction = {
  type: typeof ENROLL_CONTRACTOR,
  id: string,
};

export type RejectContractorAction = {
  type: typeof REJECT_CONTRACTOR,
  id: string,
};

export type RemoveContractorAction = {
  type: typeof REMOVE_CONTRACTOR,
  id: string,
};

export type ContractorsActions =
  | SetContractorsAction
  | EnrollContractorAction
  | RejectContractorAction
  | RemoveContractorAction;
