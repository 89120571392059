/* @flow */
import * as ActionTypes from './actionTypes';
import type { VisitsActions } from './actionTypes';
import type { VisitsState } from './index';
import { VisitTypesList } from './index';

const initialState: VisitsState = {
  all: [],
  dashboard: [],
  tab: 'open',
  search: '',
  filters: VisitTypesList,
};

export default function messages(
  state: VisitsState = initialState,
  action: VisitsActions,
): VisitsState {
  switch (action.type) {
    case ActionTypes.PUT_VISITS:
      let all = [
        ...state.all.filter(
          v => !Boolean(action.visits.find(nv => nv.id === v.id)),
        ),
        ...action.visits,
      ];
      return {
        ...state,
        all: all,
      };
    case ActionTypes.PUT_DASHBOARD_VISITS:
      return {
        ...state,
        dashboard: action.visits,
      };
    case ActionTypes.ADD_VISIT:
      return {
        ...state,
        all: [...state.all, action.visit],
      };
    case ActionTypes.UPDATE_VISIT:
      return {
        ...state,
        all: state.all.map(v => (v.id === action.visit.id ? action.visit : v)),
      };
    case ActionTypes.VERIFY_VISIT:
      return {
        ...state,
        all: state.all.map(visit => {
          const copy = {...visit};

          if (visit.id === action.id) {
            copy.status = 'Verified';
            return copy;
          }

          return copy;
        })
      }
    case ActionTypes.CANCEL_VISIT:
      return {
        ...state,
        all: state.all.map(visit => {
          const copy = {...visit};

          if (visit.id === action.id) {
            copy.status = 'Canceled';
            return copy;
          }

          return copy;
        })
      }
    case ActionTypes.REMOVE_VISIT:
      return {
        ...state,
        all: state.all.map(visit => {
          const copy = {...visit};

          if (visit.id === action.id) {
            copy.status = 'Deleted';
            return copy;
          }

          return copy;
        })
      }
    case 'SET_VISITS_TAB':
      return {
        ...state,
        tab: action.tab,
      };
    case 'SET_VISITS_SEARCH':
      return {
        ...state,
        search: action.search,
      };
    case 'RESET':
      return {
        ...initialState,
      }
    case ActionTypes.SET_VISITS_FILTERS:
      return {
        ...state,
        filters: action.filters,
      }
    default:
      return state;
  }
}
