/* @flow */
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  callGetContractors,
  callEnrollContractor,
  callRejectContractor, callUnenrollContractor,
  callGetContractor
} from '../api/agencies';
import { store } from 'data';
import ContractorActions from 'data/contractors/actions';
import { ContractorConverter } from 'api/agencies/converters';

export function* getContractors(): Generator<Object, void, void> {
  try {
    yield put({ type: 'GET_CONTRACTORS_REQUEST' });
    const dtos: Object = yield call(callGetContractors);
    const agencyId = store.getState().user.agencyId;
    const entities = new ContractorConverter().toEntityArray(dtos);
    yield put(ContractorActions.setContractors(entities, agencyId));
    yield put({ type: 'GET_CONTRACTORS_SUCCESS' });
  } catch (error) {
    yield put({
      type: 'GET_CONTRACTORS_FAILURE',
      error: error.message,
    });
  }
}

export function* getContractor(action: { id: string }): Generator<Object, void, void> {
  try {
    yield put({ type: 'GET_CONTRACTOR_REQUEST' });
    const dtos: Object = yield call(callGetContractor, action.id);
    const agencyId = store.getState().user.agencyId;
    const entities = new ContractorConverter().toEntityArray([dtos]);
    yield put(ContractorActions.setContractors(entities, agencyId));
    yield put({ type: 'GET_CONTRACTOR_SUCCESS' });
  } catch (error) {
    yield put({
      type: 'GET_CONTRACTOR_FAILURE',
      error: error.message,
    });
  }
}

export function* enroll(action: { id: string }): Generator<Object, void, void> {
  try {
    yield put({ type: 'ENROLL_CONTRACTOR_REQUEST' });
    const response: Object = yield call(callEnrollContractor, action.id);
    yield put(ContractorActions.enrollContractor(action.id));
    yield put({ type: 'ENROLL_CONTRACTOR_SUCCESS' });
  } catch (error) {
    yield put({
      type: 'ENROLL_CONTRACTOR_FAILURE',
      error: error.message,
    });
  }
}

export function* reject(action: { id: string }): Generator<Object, void, void> {
  try {
    yield put({ type: 'REJECT_CONTRACTOR_REQUEST' });
    const response: Object = yield call(callRejectContractor, action.id);
    yield put(ContractorActions.rejectContractor(action.id));
    yield put({ type: 'REJECT_CONTRACTOR_SUCCESS' });
  } catch (error) {
    yield put({
      type: 'REJECT_CONTRACTOR_FAILURE',
      error: error.message,
    });
  }
}

export function* removeContractor(action: { id: string, onSuccess: () => void }): Generator<Object, void, void> {
  try {
    yield put({ type: 'REMOVE_CONTRACTOR_REQUEST' });
    const response: Object = yield call(callUnenrollContractor, action.id);

    if (action.onSuccess) {
      action.onSuccess();
    }

    yield put(ContractorActions.removeContractor(action.id));
    yield put({ type: 'REMOVE_CONTRACTOR_SUCCESS' });
  } catch (error) {
    yield put({
      type: 'REMOVE_CONTRACTOR_FAILURE',
      error: error.message,
    });
  }
}

const contractorsSaga = [
  takeLatest('GET_CONTRACTORS', getContractors),
  takeLatest('GET_CONTRACTOR', getContractor),
  takeLatest('ENROLL_CONTRACTOR', enroll),
  takeLatest('REJECT_CONTRACTOR', reject),
  takeLatest('REMOVE_CONTRACTOR', removeContractor)
];

export default contractorsSaga;
