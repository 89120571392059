/* @flow */
import Converter from '../converter';
import type { ManagerDto, ContractorDto } from './types';
import type { Manager } from 'data/agencies';
import type { Contractor } from 'data/contractors';

export class ManagerConverter extends Converter<ManagerDto, Manager> {
  toEntity(dto: ManagerDto): Manager {
    return {
      id: dto._id,
      name: dto.name,
      phone: dto.phone,
      ext: dto.ext,
    };
  }
}

export class ContractorConverter extends Converter<ContractorDto, Contractor> {
  toEntity(dto: ContractorDto): Contractor {
    return {
      id: dto._id,
      title: 'TBD',
      name: dto.name,
      agencies: dto.enrolled_agencies.map(e => ({
        id: e.agency_id,
        state: e.state,
      })),
      enrollment: null,
      documents: dto.documents,
      activeVisits: dto.visits.filter(item => item.status === 'Accepted').length,
      pendingVisits: dto.visits.filter(item => item.status === 'Pending').length,
      canceledVisits: dto.visits.filter(item => item.status === 'Canceled').length,
      rating: 3,
      email: dto.email
    };
  }
}
