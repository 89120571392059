import React from 'react';
import { connect } from 'react-redux';
import { SideNavPage } from 'listertech-commons-web';
import Navbar from 'navigation/Navbar';

const SideNav = props => {
  return (
    <SideNavPage name={props.name} navbar={<Navbar/>} padding={props.padding}>
      {props.children}
    </SideNavPage>
  );
};

const mapStateToProps = state => ({
  name: state.user.name,
});

export default connect(mapStateToProps)(SideNav);
