/* @flow */
import * as ActionTypes from './actionTypes';
import type { PaginationActions } from './actionTypes';
import type { PaginationState } from './index';
import { PAGE_SIZE } from './config';

const emptyPaginator = {
  objectCount: null,
  pagesCount: null,
  currentPage: null,
  pages: {},
};

const initialState: PaginationState = {
  open: emptyPaginator,
  accepted: emptyPaginator,
  pending: emptyPaginator,
  canceled: emptyPaginator,
  verified: emptyPaginator,
};

export default function messages(
  state: PaginationState = initialState,
  action: PaginationActions,
): PaginationState {
  switch (action.type) {
    case ActionTypes.SET_PAGE:
      let countChanged = action.count !== state[action.key].objectCount;
      let pages = countChanged
        ? { [action.page]: action.ids }
        : { ...state[action.key]['pages'], [action.page]: action.ids };
      return {
        ...state,
        [action.key]: {
          objectCount: action.count,
          pagesCount: Math.ceil(action.count / PAGE_SIZE),
          currentPage: action.page,
          pages,
        },
      };
    case ActionTypes.SET_ACTIVE_PAGE:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          currentPage: action.page,
        },
      };
    case ActionTypes.CLEAR_PAGES:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          objectCount: null,
          pagesCount: null,
          pages: {},
        },
      };
    case ActionTypes.TAKE_FROM: {
      const paginatorData = state[action.key];

      if (paginatorData.objectCount === 0 || !paginatorData.pages[paginatorData.currentPage]) {
        return state;
      }

      return {
        ...state,
        [action.key]: {
          ...paginatorData,
          objectCount: paginatorData.objectCount - 1,
          pagesCount: Math.ceil((paginatorData.objectCount - 1) / PAGE_SIZE),
          pages: {
            ...paginatorData.pages,
            [paginatorData.currentPage]: paginatorData.pages[paginatorData.currentPage].filter(id => id !== action.id),
          },
        },
      };
    }
    case 'RESET':
      return {
        ...initialState,
      }
    default:
      return state;
  }
}
