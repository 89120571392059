/* @flow */
import type { Manager } from './index';
import type { Patient } from '../visits';

export const SET_MANAGERS = 'agency/SET_MANAGERS';
export const SET_PATIENT_SUGGESTIONS = 'agency/SET_PATIENT_SUGGESTIONS';
export const SET_DETAILS = 'agency/SET_DETAILS';
export const CLEAR = 'agency/CLEAR';

export type SetManagersAction = {
  type: typeof SET_MANAGERS,
  managers: Manager[],
};

export type SetPatientSuggestionsAction = {
  type: typeof SET_PATIENT_SUGGESTIONS,
  patients: Patient[],
};

export type ClearAction = {
  type: typeof CLEAR,
};

export type AgencyActions = SetManagersAction | SetPatientSuggestionsAction | ClearAction;
