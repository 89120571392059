/* @flow */

import React from 'react';
import { Row, Col } from 'react-grid-system';
import type { RouterHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Images,
  PageHeader,
  OptionButtonGroup,
  MenuOptionButton,
  PageDetailHeader,
  Field,
  LoadingPage,
  LoadingIndicator,
} from 'listertech-commons-web';

import SideNavPage from 'components/SideNavPage';
import type { Visit } from 'data/visits';

import MessageIcon from './MessageIcon';
import { confirmAlert } from 'react-confirm-alert';

type Props = {
  history: RouterHistory,
  match: any,
  visit: ?Visit,
};

class VisitDetailsPage extends React.Component<Props> {
  componentDidMount() {
    if (!this.props.visit) {
      this.props.getVisit(this.props.match.params.visitId);
    }
  }

  handleBack = () => {
    this.props.history.goBack();
  };

  handleEditVisit = () => {
    const { history, match } = this.props;
    history.push({
      pathname: '/visits/edit',
      state: {
        id: match.params.visitId,
      },
    });
  };

  showConfirmAlert = (title, message, onConfirm) => {
    confirmAlert({
      title,
      message,
      buttons: [
        {
          label: 'Yes',
          onClick: onConfirm,
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  handleCancelVisit = () => {
    this.showConfirmAlert(
      'Cancel visit',
      'Are you sure you want to cancel this visit?',
      () => this.props.cancelVisit(this.props.visit.id, 'open'),
    );
  };

  handleCancelAcceptedVisit = () => {
    this.showConfirmAlert(
      'Cancel visit',
      'Are you sure you want to cancel this visit?',
      () => this.props.cancelVisit(this.props.visit.id, 'accepted'),
    );
  };

  handlePendingVerify = () => {
    this.showConfirmAlert(
      'Verify visit',
      'Are you sure you want to verify this visit?',
      () => this.props.verifyVisit(this.props.visit.id),
    );
  };

  handlePendingReject = () => {
    this.showConfirmAlert(
      'Reject visit',
      'Are you sure you want to reject this visit?',
      () => this.props.rejectVisit(this.props.visit.id),
    );
  };

  handleRemoveCanceled = () => {
    this.showConfirmAlert(
      'Remove visit',
      'Are you sure you want to remove this visit?',
      () => this.props.removeVisit(this.props.visit.id, 'canceled'),
    );
  };

  handleRepostVisit = id => {
    const { history, match } = this.props;
    this.props.history.push({
      pathname: '/visits/add',
      state: {
        id: match.params.visitId,
      },
    });
  };

  handleOpenMap = address => {
    const url = `https://www.google.com/maps/search/${address}`;
    window.open(url, '_blank');
  };

  formatDate = (date, format = 'MM/DD') => {
    return date ? moment(date).format(format) : '';
  };

  formatAddress = ({ address, apartment, city, state, zip }) => {
    let result = '';
    if (apartment) {
      result += `Apartment ${apartment}, `;
    }
    if (address) {
      result += `${address}, `;
    }
    if (city) {
      result += `${city}, `;
    }
    if (state) {
      result += `${state} `;
    }
    if (zip) {
      result += `${zip}`;
    }
    return result;
  };

  getActionButtons = () => {
    if (!this.props.visit) return [];

    switch (this.props.visit.status) {
      case 'Open':
        return [
          <MenuOptionButton onClick={this.handleEditVisit}>
            Edit
          </MenuOptionButton>,
          <MenuOptionButton red onClick={this.handleCancelVisit}>
            Cancel
          </MenuOptionButton>,
        ];
      case 'Pending':
        return [
          <MenuOptionButton green onClick={this.handlePendingVerify}>
            Verify
          </MenuOptionButton>,
          <MenuOptionButton red onClick={this.handlePendingReject}>
            Reject
          </MenuOptionButton>,
        ];
      case 'Accepted':
        return [
          <MenuOptionButton green onClick={this.handleRepostVisit}>
            Repost
          </MenuOptionButton>,
          <MenuOptionButton red onClick={this.handleCancelAcceptedVisit}>
            Cancel
          </MenuOptionButton>,
        ];
      case 'Canceled':
        return [
          <MenuOptionButton green onClick={this.handleRepostVisit}>
            Repost
          </MenuOptionButton>,
        ];
      case 'Rejected':
        return [
          <MenuOptionButton red onClick={this.handleRemoveCanceled}>
            Remove
          </MenuOptionButton>,
        ];
      default:
        return [];
    }
  };

  render() {
    const { visit = { patient: {}, manager: {}, doctor: {} } } = this.props;
    const patientAddress = this.formatAddress(visit.patient);

    if (this.props.loading)
      return (
        <SideNavPage>
          <LoadingIndicator />
        </SideNavPage>
      );

    return (
      <SideNavPage>
        <Row justify="between">
          <PageHeader>Visit Detail</PageHeader>
          <OptionButtonGroup>
            <MenuOptionButton onClick={this.handleBack}>Back</MenuOptionButton>
            {this.getActionButtons()}
          </OptionButtonGroup>
        </Row>
        <PageDetailHeader>Visit Info</PageDetailHeader>
        <Row>
          <Col sm={12} md={4} lg={3} xl={2}>
            <Field label="Visit Type" value={visit.type} />
          </Col>
          <Col sm={12} md={4} lg={3} xl={2}>
            <Field label="Date" value={this.formatDate(visit.date)} />
          </Col>
        </Row>
        <PageDetailHeader>Patient Info</PageDetailHeader>
        <Row>
          <Col sm={8} md={7} lg={3} xl={3}>
            <Field label="Patient Name" value={visit.patient.name} />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={7} lg={3} xl={3}>
            <Field label="Details" value={visit.patient.diagnosis || '-'} />
          </Col>
          <Col sm={12} md={4} lg={2} xl={2}>
            <Field label="Visits" value={visit.patient.visits || '-'} />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={7} lg={3} xl={3}>
            <Field label="Address" value={patientAddress} />
            <MenuOptionButton
              onClick={() => this.handleOpenMap(patientAddress)}
            >
              Map
            </MenuOptionButton>
          </Col>
          <Col sm={12} md={4} lg={2} xl={2}>
            <Field label="Patient Phone" value={visit.patient.phone || '-'} />
          </Col>
        </Row>
      </SideNavPage>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let visit = state.visits.all.find(
    v => v.id === ownProps.match.params.visitId,
  );

  if (!visit) {
    visit = state.visits.dashboard.find(
      v => v.id === ownProps.match.params.visitId,
    );
  }

  return {
    visit,
    loading: state.loading['GET_VISIT'],
  };
};

const mapDispatchToProps = dispatch => ({
  getVisit: id => dispatch({ type: 'GET_VISIT', id }),
  verifyVisit: id => dispatch({ type: 'VERIFY_VISIT', id }),
  cancelVisit: (id, tab) => dispatch({ type: 'CANCEL_VISIT', id, tab }),
  rejectVisit: id => dispatch({ type: 'REJECT_VISIT', id }),
  removeVisit: (id, tab) => dispatch({ type: 'REMOVE_VISIT', id, tab }),
});

export default connect(mapStateToProps, mapDispatchToProps)(VisitDetailsPage);
