/* @flow */
export default class Converter<Dto, Entity> {
  toEntityArray(dtoList: Dto[]): Entity[] {
    let entities: Entity[] = [];
    for (let i = 0; i < dtoList.length; i++) {
      entities.push(this.toEntity(dtoList[i]));
    }
    return entities;
  }

  toEntity(dto: Dto): Entity {
    throw 'Method must be overriden';
  }

  toDto(entity: Entity): Dto {
    throw 'Method must be overriden';
  }
}
